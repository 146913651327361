// src/components/MobileSidebar.jsx
import React, { useEffect } from 'react'; // Importar useEffect
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import chatIcon from '../assets/icon-chat.png';
import personIcon from '../assets/icon-person.png';
import dashboardIcon from '../assets/icon-dashboard.png';
import chartsIcon from '../assets/icon-charts.png';
import supportIcon from '../assets/icon-support.png';
import profileSidebarIcon from '../assets/profile-sidebar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import './MobileSidebar.css'; // Asegúrate de que este archivo exista

const MobileSidebar = ({ isVisible, onClose }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  // useEffect para gestionar la clase en el body
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('mobile-sidebar-visible');
    } else {
      document.body.classList.remove('mobile-sidebar-visible');
    }

    // Limpieza al desmontar el componente
    return () => {
      document.body.classList.remove('mobile-sidebar-visible');
    };
  }, [isVisible]);

  const handleLogout = async () => {
    try { 
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
    if(onClose) onClose();
  };

  return (
    <div className={`mobile-sidebar ${isVisible ? 'mobile-sidebar-visible' : 'mobile-sidebar-hidden'}`}>
      <div className="mobile-sidebar-content">
        {/* Sección superior de iconos */}
        <div className="mobile-sidebar-top">

          <Link to="/messages" className="mobile-sidebar-icon" data-tooltip="Mensajes" onClick={onClose}>
            <img src={chatIcon} alt="Chat" />
          </Link>
          <Link to="/crm" className="mobile-sidebar-icon" data-tooltip="CRM" onClick={onClose}>
            <img src={dashboardIcon} alt="Dashboard" />
          </Link>
          <Link to="/analytics" className="mobile-sidebar-icon" data-tooltip="Analíticas" onClick={onClose}>
            <img src={chartsIcon} alt="Charts" />
          </Link>
        </div>

        {/* Sección inferior de iconos */}
        <div className="mobile-sidebar-bottom">
        <Link to="/" className="mobile-sidebar-icon" data-tooltip="Agentes" onClick={onClose}>
            <img src={personIcon} alt="Agentes" />
          </Link>
           {/*  <Link to="/support" className="mobile-sidebar-icon" data-tooltip="Soporte" onClick={onClose}>
            <img src={supportIcon} alt="Support" />
          </Link> */}
          <Link to="/profile" className="mobile-sidebar-icon" data-tooltip="Perfil" onClick={onClose}>
    {/* Cambiamos paymentsIcon por profileSidebarIcon */}
    <img src={profileSidebarIcon} alt="Profile" />
  </Link>
          <button 
            onClick={handleLogout} 
            className="mobile-sidebar-icon mobile-logout-button" 
            data-tooltip="Cerrar sesión"
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;
