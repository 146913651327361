import React, { useState, useEffect } from 'react';
import { createKnowledgeBase, uploadFilesToKnowledgeBase, addFileToVectorStore, getVectorStoreFiles, getFileDetails, deleteKnowledgeBase, deleteFileFromVectorStore } from '../services/openaiService';
import { db } from '../firebase';
import { doc, setDoc, deleteDoc, getDocs, collection, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';

const CreateKnowledgeBase = ({ onKnowledgeBaseCreated, knowledgeBaseToEdit, teamMembers = [] }) => {
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);  // Múltiples archivos subidos
  const [associatedFiles, setAssociatedFiles] = useState([]);  // Archivos ya asociados al vector store
  const [isUploading, setIsUploading] = useState(false);
  const [filesReadyToAssociate, setFilesReadyToAssociate] = useState(false);
  
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para prevenir envíos múltiples


  const { currentUser } = useAuth(); // Obtenemos el usuario actual

  useEffect(() => {
    if (knowledgeBaseToEdit) {
      setName(knowledgeBaseToEdit.name);
      fetchAssociatedFiles();
    }
  }, [knowledgeBaseToEdit]);

  

  const fetchAssociatedFiles = async () => {
    if (knowledgeBaseToEdit && knowledgeBaseToEdit.id) {
      try {
        const files = await getVectorStoreFiles(knowledgeBaseToEdit.id);
        const fileDetailsPromises = files.map(file => getFileDetails(file.id));
        const fileDetails = await Promise.all(fileDetailsPromises);

        const formattedFiles = fileDetails.map(file => ({
          id: file.id,
          filename: file.filename || 'Name not available',
        }));
    
        setAssociatedFiles(formattedFiles);
      } catch (error) {
        console.error('Error fetching associated files:', error);
        setStatus('Error fetching associated files: ' + error.message);
      }
    }
  };

  const handleDeleteKnowledgeBase = async () => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta base de conocimiento? Esta acción no se puede deshacer.')) {
      try {
        // Eliminar la base de conocimiento en OpenAI
        await deleteKnowledgeBase(knowledgeBaseToEdit.id);
  
        // Eliminar la base de conocimiento en Firestore
        await deleteDoc(doc(db, 'bases', knowledgeBaseToEdit.id));
  
        alert('Base de conocimiento eliminada correctamente.');
        onKnowledgeBaseCreated(null); // Notifica al componente padre que se ha eliminado
      } catch (error) {
        console.error('Error al eliminar la base de conocimiento:', error);
        alert('No se pudo eliminar la base de conocimiento. Por favor, intenta nuevamente.');
      }
    }
  };
  
  
  const handleDeleteFile = async (fileId) => {
    if (!knowledgeBaseToEdit || !knowledgeBaseToEdit.id) return;
  
    if (window.confirm('¿Estás seguro de que deseas eliminar este archivo? Esta acción no se puede deshacer.')) {
      try {
        await deleteFileFromVectorStore(knowledgeBaseToEdit.id, fileId);
        setAssociatedFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
        setStatus('Archivo eliminado correctamente');
      } catch (error) {
        console.error('Error borrando archivo:', error);
        setStatus('Error borrando archivo. Por favor intente nuevamente');
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      console.log('Envío ya en proceso. Acción prevenido.');
      return; // Prevenir múltiples envíos
    }
  
    setIsSubmitting(true); // Indicar que el envío está en curso

    if (!name) {
      setStatus('Por favor, introduce un nombre para la Base.');
      return;
    }


    try {
      // Asegurarse de que teamMembers es un array antes de llamar a .map
      const allowedUsers = Array.isArray(teamMembers) 
        ? teamMembers.map(member => member.id).concat(currentUser.uid) 
        : [currentUser.uid];

      // Crear la base de conocimiento en OpenAI
      const knowledgeBase = await createKnowledgeBase(name);

      if (knowledgeBase) {
        // Guardar la base de conocimiento en Firestore con creationDate
        const baseData = {
          id: knowledgeBase.id,
          name: name,
          allowedUsers, // Incluir a todos los miembros del equipo
          createdAt: serverTimestamp(), // Agregar creationDate
        };

        // Usar el ID proporcionado por OpenAI como ID del documento en Firestore
        await setDoc(doc(db, 'bases', knowledgeBase.id), baseData);

        setStatus('Base de conocimiento creada correctamente.');
        onKnowledgeBaseCreated(knowledgeBase);
      } else {
        setStatus('Error al crear la base de conocimiento.');
      }
    } catch (error) {
      console.error('Error al crear la base de conocimiento:', error);
      setStatus('Error al crear la base de conocimiento: ' + error.message);
    } finally {
      setIsSubmitting(false); // Resetear el estado de envío
    }
  };

  const handleFileSelect = async (e) => {
    const selectedFiles = [...e.target.files];

    if (selectedFiles.length === 0) return;

    setIsUploading(true);

    try {
      const uploaded = await uploadFilesToKnowledgeBase(selectedFiles);

      if (uploaded && uploaded.length > 0) {
        setUploadedFiles(uploaded);
        setFilesReadyToAssociate(true);
        setStatus('Archivos subidos correctamente. Ahora presione "GUARDAR ARCHIVOS" para almacenarlos en la base de datos.');
      } else {
        setStatus('Error subiendo archivos.');
      }
    } catch (error) {
      console.error('Error subiendo archivos:', error);
      setStatus('Error subiendo archivos: ' + (error.response?.data?.error?.message || error.message));
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileAssociation = async () => {
    if (!knowledgeBaseToEdit || !knowledgeBaseToEdit.id || uploadedFiles.length === 0) {
      setStatus('Seleccione una base de conocimiento.');
      return;
    }

    setIsUploading(true);
    setFilesReadyToAssociate(false);
    setStatus('Guardando archivos en la base de conocimeinto...');

    try {
      const result = await addFileToVectorStore(knowledgeBaseToEdit.id, uploadedFiles);

      setAssociatedFiles(prevFiles => [
        ...prevFiles,
        ...uploadedFiles.map(file => ({ id: file.id, filename: file.filename || 'Nombre no disponible' }))
      ]);
      setUploadedFiles([]);
      setStatus('Archivos añadidos correctamente a la base de conocimiento.');
    } catch (error) {
      console.error('Error guardando archivos:', error);
      setStatus('Error guardando archivos: ' + (error.response?.data?.error?.message || error.message));
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
<h2 className="crear-asistente">
  {knowledgeBaseToEdit ? `Editar "${knowledgeBaseToEdit.name || 'Base de conocimiento'}"` : 'Crear Base de conocimiento'}
</h2>


      {!knowledgeBaseToEdit && (
        <form onSubmit={handleSubmit} className="auth-form">
          <label className="auth-label" htmlFor="name">
          <p className='nombre-asistente'>Nombre de tu base <span className="required">*</span></p>
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Base para agente de soporte técnico"
            className="crear-base-input"
            required
          />
<button type="submit" className="create-button" disabled={isSubmitting}>
  {isSubmitting ? 'Creando...' : 'Crear base de conocimiento'}
</button>

        </form>
      )}

{knowledgeBaseToEdit && (
  <>
    <h3 className='subir-archivos'>Subir archivos a la base de conocimiento</h3>
    <p className="subir-archivos">
      Soporta <strong>PDF, TXT, DOCX, PPT, PPTX, y más</strong> <br />
      <span className="file-size-text">( 50 MB máx. cada uno )</span>
    </p>
    {isUploading && <p className='subiendo'>Subiendo...</p>}
    <div className="upload-button" style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
      <label htmlFor="file-upload" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>



        <span>Subir archivos</span>
        <i className="fa fa-cloud-upload upload-icon" aria-hidden="true"></i>
      </label>
      <input
        id="file-upload"
        type="file"
        multiple
        onChange={handleFileSelect}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          cursor: 'pointer',
        }}
      />
      
    </div>



  </>
)}

      {filesReadyToAssociate && (
        <button onClick={handleFileAssociation} className="auth-button">Guardar archivos</button>
      )}

{associatedFiles.length > 0 && (
  <div>
    <h3 className='base-archivos'>Archivos en esta base:</h3>
    <ul className="archivos-lista">
      {associatedFiles.map((file) => (
        <li className="archivos-asociados" key={file.id}>
  <span className="file-text">{file.filename}</span>
  <i
    className="fa fa-check check-icon"
    aria-hidden="true"
    onMouseEnter={(e) => e.currentTarget.classList.replace('fa-check', 'fa-times')}
    onMouseLeave={(e) => e.currentTarget.classList.replace('fa-times', 'fa-check')}
    onClick={() => handleDeleteFile(file.id)}
    style={{ cursor: 'pointer', marginLeft: '10px' }}
  ></i>
</li>

      ))}
    </ul>

  </div>

)}
    <div className="delete-button-div">
    <button onClick={handleDeleteKnowledgeBase} className="delete-agent-button">
Borrar base de conocimiento
    </button>
  </div>
      <p>{status}</p>
    </div>
  );
};

export default CreateKnowledgeBase;
