import React from 'react';
import './SubscriptionExpiredModal.css'; // NUEVO archivo CSS responsive
import { useSubscription } from '../context/SubscriptionContext';

const SubscriptionExpiredModal = () => {
  const { isExpired, loading } = useSubscription();

  // No mostrar nada si todavía estamos 'loading'
  // o si no está vencida:
  if (loading || !isExpired) return null;

  return (
    <div className="subscription-modal-backdrop">
      <div className="subscription-modal">
        <h2>Suscripción Vencida</h2>
        <p>
          Tu suscripción ha vencido. Por favor, dirígete a{' '}
          <a
            href="https://thethingapp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://thethingapp.com
          </a>{' '}
          para renovarla.
        </p>
        <p>No podrás realizar ninguna acción hasta que renueves tu suscripción.</p>
        <button
          className="auth-button"
          onClick={() => window.open('https://thethingapp.com', '_blank')}
        >
          Renovar Suscripción
        </button>
      </div>
    </div>
  );
};

export default SubscriptionExpiredModal;
