import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import chatIcon from '../assets/icon-chat.png';
import personIcon from '../assets/icon-person.png';
import dashboardIcon from '../assets/icon-dashboard.png';
import chartsIcon from '../assets/icon-charts.png';
import customIcon from '../assets/icon-custom.png';
import supportIcon from '../assets/icon-support.png';
// Ejemplo en Sidebar.jsx
import profileSidebarIcon from '../assets/profile-sidebar.png'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try { 
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  return (
    <div className="sidebar">
      {/* Sección superior de iconos */} 
      <div className="sidebar-top">
        <Link to="/messages" className="sidebar-icon" data-tooltip="Mensajes">
          <img src={chatIcon} alt="Chat" />
        </Link>
        <Link to="/crm" className="sidebar-icon" data-tooltip="CRM">
          <img src={dashboardIcon} alt="Dashboard" />
        </Link>
        <Link to="/analytics" className="sidebar-icon" data-tooltip="Analíticas">
          <img src={chartsIcon} alt="Charts" />
        </Link>
      </div>

      {/* Sección inferior de iconos */}
      <div className="sidebar-bottom">
        {/* <Link to="" className="sidebar-icon" data-tooltip="Integraciones">
          <img src={customIcon} alt="Custom" />
        </Link> */}
              <Link to="/" className="sidebar-icon" data-tooltip="Agentes">
          <img src={personIcon} alt="Agentes" />
        </Link>
       {/*  <Link to="/support" className="sidebar-icon" data-tooltip="Soporte">
          <img src={supportIcon} alt="Support" />
        </Link> */}
        <Link to="/profile" className="sidebar-icon" data-tooltip="Perfil">
    {/* Cambiamos paymentsIcon por profileSidebarIcon */}
    <img src={profileSidebarIcon} alt="Profile" /> 
  </Link>
        <button 
          onClick={handleLogout} 
          className="sidebar-icon logout-button" 
          data-tooltip="Cerrar sesión"
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
