/* global FB */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  getAssistantDetails, 
  getAllVectorStores, 
  associateVectorStoreToAssistant, 
  enableFileSearchForAssistant,
  disableFileSearchForAssistant,
  sendMessage,  
  updateAssistantInstructions,
  deleteAssistant,
  updateAssistantName
} from '../services/openaiService'; 
import './AssistantChat.css'; // Importa los estilos específicos de AssistantChat
import logo from '../assets/logotheting.png';  // Importa el logo
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import { db } from '../firebase'; // Importa la instancia correcta de Firestore
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore'; 
import { auth } from '../firebase';
import { onAuthStateChanged, getAuth } from 'firebase/auth'; 
import { AssistantContext } from '../context/AssistantContext';
// Importa el paquete de selectores de color
import { SketchPicker } from 'react-color'; // Asegúrate de instalar react-color

// Importa las imágenes predeterminadas
import defaultBubbleBlack from '../assets/Burbuja-chat-predeterminada-negra.png';
import defaultBubbleViolet from '../assets/Burbuja-chat-predeterminada-violeta.png';
import defaultBubbleWhite from '../assets/Burbuja-chat-predeterminada-blanca.png'; 
import defaultBubbleRed from '../assets/Burbuja-chat-predeterminada-rojo.png';
import defaultBubbleGreen from '../assets/Burbuja-chat-predeterminada-verde.png';
import defaultBubbleBlue from '../assets/Burbuja-chat-predeterminada-azul.png';

import { useAuth } from '../context/AuthContext'; // Asegúrate de tener este contexto definido

// src/components/AssistantChat.js

import paperClipButton from '../assets/paper-clip-button.png'; // Ajusta la ruta según tu estructura de carpetas


 

const AssistantChat = () => {
  const [isFacebookSDKReady, setIsFacebookSDKReady] = useState(false);
  const { assistantId } = useParams();

  const { assistantName, setAssistantName } = useContext(AssistantContext);

  
  const [assistantDetails, setAssistantDetails] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [vectorStores, setVectorStores] = useState([]); 
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState('');
  const [isKnowledgeBaseEnabled, setIsKnowledgeBaseEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isEditingInstructions, setIsEditingInstructions] = useState(false);
  const [newInstructions, setNewInstructions] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para el modal
  const [apiKey, setApiKey] = useState(localStorage.getItem('API_KEY') || '');
  const [passKey, setPassKey] = useState(null); 
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [newName, setNewName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);

  const [activePlatform, setActivePlatform] = useState('whatsapp');

  const [headerColor, setHeaderColor] = useState('#6268D4');
const [bubbleButtonColor, setBubbleButtonColor] = useState('#FFFFFF0');
const [messageUserColor, setMessageUserColor] = useState('#57238F');
const [messageAssistantColor, setMessageAssistantColor] = useState('#406AD5');

const [chatIconButtonColor, setChatIconButtonColor] = useState('#693DD7');
const [isChatIconButtonColorPickerOpen, setIsChatIconButtonColorPickerOpen] = useState(false);

const [bubbleButtonImage, setBubbleButtonImage] = useState(''); // Para la imagen del botón de la burbuja

const [useBackgroundImage, setUseBackgroundImage] = useState(true);
const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

const [assistantMessagesQueue, setAssistantMessagesQueue] = useState([]);

const { currentUser } = useAuth();


// AssistantChat.js
const defaultBubbleBlack = 'https://platform.thethingapp.com/images/Burbuja-chat-predeterminada-negra.png';
const defaultBubbleViolet = 'https://platform.thethingapp.com/images/Burbuja-chat-predeterminada-violeta.png';
const defaultBubbleWhite = 'https://platform.thethingapp.com/images/Burbuja-chat-predeterminada-blanca.png';
const defaultBubbleRed = 'https://platform.thethingapp.com/images/Burbuja-chat-predeterminada-rojo.png';
const defaultBubbleGreen = 'https://platform.thethingapp.com/images/Burbuja-chat-predeterminada-verde.png';
const defaultBubbleBlue = 'https://platform.thethingapp.com/images/Burbuja-chat-predeterminada-azul.png';

// Dentro del componente
const modalRef = useRef(null);

const [activeTab, setActiveTab] = useState('codigo'); // 'codigo' es la pestaña predeterminada


  // Añade estos estados al inicio del componente
  const [pin, setPin] = useState('');
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  // Añade este estado al inicio del componente
const [currentUserUid, setCurrentUserUid] = useState(null);



  // Estados para manejar la apertura de los color pickers
  const [isHeaderColorPickerOpen, setIsHeaderColorPickerOpen] = useState(false);
  const [isBubbleButtonColorPickerOpen, setIsBubbleButtonColorPickerOpen] = useState(false);
  const [isMessageUserColorPickerOpen, setIsMessageUserColorPickerOpen] = useState(false);
  const [isMessageAssistantColorPickerOpen, setIsMessageAssistantColorPickerOpen] = useState(false);
  const [isBackgroundColorPickerOpen, setIsBackgroundColorPickerOpen] = useState(false);

  const [showChatIconButton, setShowChatIconButton] = useState(true);


  // Estado para detectar si la pantalla es móvil
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1280);
    };

    // Añadir listener para eventos de resize
    window.addEventListener('resize', handleResize);

    // Limpiar el listener al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Función para abrir el modal del PIN 
  const handleOpenPinModal = () => setIsPinModalOpen(true);
  const handleClosePinModal = () => setIsPinModalOpen(false);
  useEffect(() => {
    const fetchApiKeyAndPassKey = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const token = await user.getIdToken();
  
        try {
          // Obtener la API_KEY
          const response = await fetch(`https://backend.thethingapp.com/api/get-openai-key?userId=${userId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }); 
          const data = await response.json();
  
          if (data.apiKey) {
            localStorage.setItem('API_KEY', data.apiKey);
            setApiKey(data.apiKey);
  
            // Obtener el PASS_KEY
            const passKeyResponse = await fetch(`https://backend.thethingapp.com/api/get-passkey?assistantId=${assistantId}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            const passKeyData = await passKeyResponse.json();
  
            if (passKeyData.success) {
              setPassKey(passKeyData.passKey);
              
            } else {
              console.error('Error obteniendo el PASS_KEY:', passKeyData.error);
            }
          } else {
            console.error('Error obteniendo la API Key:', data.error);
          }
        } catch (error) {
          console.error('Error al obtener la API Key o PASS_KEY:', error);
        }
      } else {
        
      }
    };
  
    fetchApiKeyAndPassKey();
  }, [assistantId]);
  
  useEffect(() => {
    // Función para limpiar localStorage al iniciar la app
    const clearLocalStorage = () => {
      localStorage.removeItem(`WHATSAPP_PHONE_NUMBER_${assistantId}`);
      localStorage.removeItem(`WHATSAPP_PHONE_NUMBER_ID_${assistantId}`);
      localStorage.removeItem(`WABA_ID_${assistantId}`);
    };

    clearLocalStorage(); // Limpiar los datos al cargar la app

    const fetchPhoneNumber = async () => {
      try {
        const assistantRef = doc(db, 'assistants', assistantId);
        const assistantDoc = await getDoc(assistantRef);
  
        if (assistantDoc.exists) {
          const data = assistantDoc.data();
          if (data.phone_number && data.phone_number_id) {
           
            setPhoneNumbers([{
              id: data.phone_number_id,
              phone_number_id: data.phone_number_id,
              phone_number: data.phone_number,
              waba_id: data.waba_id,
            }]);
            setSelectedPhoneNumber(data.phone_number_id);
          } else {
            
            setPhoneNumbers([]);
          }
        } else {
          
          await assistantRef.set({ phone_number_id: '', phone_number: '', waba_id: '' });
          setPhoneNumbers([]);
        }
      } catch (error) {
        console.error('Error obteniendo el número de WhatsApp:', error);
      }
    };
  
    fetchPhoneNumber();
  }, [assistantId]);

  const handlePhoneNumberChange = (e) => {
    setSelectedPhoneNumber(e.target.value);
  };

  // Función para manejar el envío del PIN
  const handleSubmitPin = () => {
    if (pin.length !== 6 || !/^\d{6}$/.test(pin)) {
      
      return;
    }
    if (isSubmitting) return; // Prevenir múltiples envíos
    setIsSubmitting(true); // Indicar que el envío está en curso


    // Obtener el número de WhatsApp seleccionado desde el estado
    const selectedNumber = phoneNumbers.find(num => num.phone_number_id === selectedPhoneNumber);

    if (!selectedNumber) {
     
      return;
    }

    const { phone_number_id, waba_id } = selectedNumber;

    // Enviar los datos al backend para registrar el número
    fetch('https://backend.thethingapp.com/register-whatsapp-number', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        phone_number_id,
        waba_id,
        assistantId,
        pin,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
        
            setIsSubmitting(false); // Resetear el estado de envío
          // Cerrar el modal y actualizar el estado según sea necesario
          setIsPinModalOpen(false);
          // Opcional: Actualizar el estado de phoneNumbers si es necesario
        } else {
          console.error('Error al registrar el número de WhatsApp:', data.error);
         
        }
      })
      .catch((error) => {
        console.error('Error en la solicitud de registro:', error);

      });
  };

  const handleEditName = () => {
    setNewName(assistantDetails.name);
    setIsEditingName(true);
};

const handleSaveName = async () => {
    try {
        await updateAssistantName(assistantId, newName, apiKey);
        setAssistantDetails({ ...assistantDetails, name: newName });
        setIsEditingName(false);
       
    } catch (error) {
        console.error("Error al actualizar el nombre:", error);
    
    }
};

const handleCancelEditName = () => {
    setNewName(assistantDetails.name);
    setIsEditingName(false);
};

useEffect(() => {
  const saveAssistantIdString = async () => {
    if (!assistantId) {
      console.error('assistantId is undefined or null.');
      return;
    }
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated.');
        return;
      }
      const userId = user.uid;

      const assistantRef = doc(db, 'assistants', assistantId);
      const assistantDoc = await getDoc(assistantRef);

      if (!assistantDoc.exists()) {
        await setDoc(
          assistantRef,
          { 
            assistantId: assistantId.toString(),
            ownerId: userId, // Agregar ownerId al crear el documento
            phone_number_id: '',
            phone_number: '',
            waba_id: '',
            createdAt: new Date().toISOString(),
          },
          { merge: true }
        );
        
      } else {
        if (!assistantDoc.data().ownerId) {
          await setDoc(
            assistantRef,
            { ownerId: userId }, // Actualizar ownerId si no existe
            { merge: true }
          );
          
        } else {
          
        }
      }
    } catch (error) {
      console.error('Error saving assistantId and ownerId:', error);
    }
  };

  saveAssistantIdString();
}, [assistantId]);


const handleDeleteAssistant = async () => {
  if (window.confirm('¿Estás seguro de que deseas eliminar este asistente? Esta acción no se puede deshacer.')) {
    try {
      // Eliminar el asistente en OpenAI
      await deleteAssistant(assistantId);
      

      // Eliminar el documento del asistente en Firestore
      const assistantRef = doc(db, 'assistants', assistantId);
      await deleteDoc(assistantRef);
      

  
      navigate('/'); // Redirige de nuevo a la página principal
    } catch (error) {
      console.error('Error eliminando el asistente:', error);
     
    }
  }
};

  
  const handleDeletePhoneNumber = async () => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este número de WhatsApp? Esta acción no se puede deshacer.')) {
      try {
        // Eliminar el documento del asistente en Firebase
        const assistantRef = doc(db, 'assistants', assistantId);
        await deleteDoc(assistantRef);
       

        // Limpiar los datos relacionados en localStorage
        localStorage.removeItem(`WHATSAPP_PHONE_NUMBER_${assistantId}`);
        localStorage.removeItem(`WHATSAPP_PHONE_NUMBER_ID_${assistantId}`);
        localStorage.removeItem(`WABA_ID_${assistantId}`);

        // Actualizar el estado para que ya no haya números disponibles
        setPhoneNumbers([]);
        setSelectedPhoneNumber('');
  
      } catch (error) {
        console.error('Error eliminando el asistente:', error);

      }
    }
  };

  const handleSave = () => {
    // Lógica para manejar la selección del número de teléfono
   
  };

  useEffect(() => {
    // Reiniciar los estados de edición al cambiar de asistente
    setIsEditingInstructions(false);
    setNewInstructions('');
    setIsEditingName(false);
    setNewName('');
    
    // También puedes limpiar otros estados si es necesario
  }, [assistantId]);
  

  useEffect(() => {
    const loadFacebookSDK = () => {
      
  
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
  
      // Inicializar el SDK de Facebook cuando esté disponible
      window.fbAsyncInit = function() {
        FB.init({
          appId: '1067207251796817', // Usa variables de entorno para mayor seguridad
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v21.0',
        });
        
        window.FBReady = true;
        setIsFacebookSDKReady(true);
      };
    };
  
    loadFacebookSDK();

    // Configuración del oyente de eventos para recibir la información de la sesión
    window.addEventListener('message', (event) => {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // Verificar si el flujo de registro está completo
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
           
            handleWhatsAppSignupComplete(phone_number_id, waba_id);
            // Guardar los IDs localmente o enviarlos al backend
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn("Cancel at ", current_step);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error("Error ", error_message);
          }
        }
      } catch (e) {
       
      }
    });
  }, []);

  // Función de callback después del inicio de sesión en Facebook
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      
      // Envía este código y el assistantId a tu backend para intercambiarlo por un access token
      fetch('https://backend.thethingapp.com/exchange-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          code,
          assistantId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            
            // Opcional: Puedes manejar alguna lógica adicional aquí si es necesario
          } else {
            console.error('Error al intercambiar el código:', data.error);
          }
        })
        .catch((error) => {
          console.error('Error en la solicitud de intercambio de código:', error);
        });
    } else {
     
    }
  };

  const startWhatsAppFlow = () => {
    if (window.FBReady) {
      FB.login((response) => {
        if (response.authResponse) {
          
          startEmbeddedSignUpFlow(response.authResponse.accessToken);
        } else {
         
        }
      }, { scope: 'whatsapp_business_management,whatsapp_business_messaging', display: 'popup' });
    } else {
      console.error('El SDK de Facebook aún no está listo.');
    }
  };

  // Inicia el flujo de registro embebido de WhatsApp
  const startEmbeddedSignUpFlow = (accessToken) => {
    FB.ui({
      method: 'business_embedded_signup',
      access_token: accessToken,
      business_id: '808344569245976',  // Reemplaza con tu Business ID de Facebook
      redirect_uri: 'https://platform.thethingapp.com/',  // Reemplaza con tu URI de redirección
    }, (response) => {
      if (response && !response.error) {
        
      } else {
        console.error('Error en el flujo de registro', response.error);
      }
    });
  };

  const launchWhatsAppSignup = () => {
    if (window.FBReady) {
      FB.login(fbLoginCallback, {
        config_id: '879398050834125', // Reemplaza con tu Config ID
        response_type: 'code', // debe estar establecido en 'code' para el token de acceso del usuario del sistema
        override_default_response_type: true, // Permitir sobreescribir el tipo de respuesta
        extras: {
          feature: 'whatsapp_embedded_signup', // Establecer el feature correctamente
          sessionInfoVersion: 3, // Actualizar a la versión 3
        }
      });
    }
  };

  // Función para manejar el registro de WhatsApp Business completo
  const axios = require('axios');

  // ... (tu código existente)
  
// La función handleWhatsAppSignupComplete actualizada
const handleWhatsAppSignupComplete = async (phone_number_id, waba_id, assistantIdParam) => {
  const assistantIdToUse = assistantIdParam || assistantId;
  try {
    // Primero, guarda los detalles del número de WhatsApp en Firebase como lo estás haciendo actualmente
    const response = await fetch('https://backend.thethingapp.com/get-whatsapp-number', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        phone_number_id, 
        waba_id, 
        assistantId: assistantIdToUse,
      }),
    });
    const data = await response.json();
    


    if (data.success) {
     
      



      // Abrir el modal para ingresar el PIN
      handleOpenPinModal();

      // Guardar en localStorage temporalmente
      localStorage.setItem(`WHATSAPP_PHONE_NUMBER_${assistantId}`, data.phone_number);
      localStorage.setItem(`WHATSAPP_PHONE_NUMBER_ID_${assistantId}`, phone_number_id);
      localStorage.setItem(`WABA_ID_${assistantId}`, waba_id);

     

      setPhoneNumbers([{
        id: phone_number_id,
        phone_number_id,
        phone_number: data.phone_number,
        waba_id,
      }]);
      // Añade assistantId como parámetro al llamar a las funciones
await subscribeAppToWABAWebhooks(waba_id, assistantId);
await checkWABASubscription(waba_id, assistantId);

      setSelectedPhoneNumber(phone_number_id); // Seleccionar automáticamente el número
    } else {
      console.error('Error guardando el número de WhatsApp:', data.error);
    }
  } catch (error) {
    console.error('Error en la solicitud:', error);
  }
};

// Función para suscribirse al webhook de la WABA
const subscribeAppToWABAWebhooks = async (wabaId, assistantId) => {
  try {
    const response = await fetch('https://backend.thethingapp.com/subscribe-waba-webhook', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wabaId, assistantId }),
    });
    const data = await response.json();
    

    if (data.success) {
      
    } else {
      
    }
  } catch (error) {
    
  }
};

// Función para verificar la suscripción al webhook de la WABA
// Function to check WABA subscription
const checkWABASubscription = async (wabaId, assistantId) => {
  try {
  
    const response = await fetch(`https://backend.thethingapp.com/check-waba-subscription?wabaId=${wabaId}&assistantId=${assistantId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();

    
  } catch (error) {
    console.error('Error al verificar la suscripción al webhook de la WABA:', error);
  }
};


  // Este efecto maneja la escucha de los eventos de finalización del registro embebido
  useEffect(() => {
    const handleMessageEvent = (event) => {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            handleWhatsAppSignupComplete(phone_number_id, waba_id, assistantId);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn("Cancel at ", current_step);
            // Puedes manejar el estado de cancelación aquí si lo deseas
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error("Error ", error_message);
            // Puedes manejar errores aquí si lo deseas
          }
        }
      } catch (e) {
        
      }
    };

    window.addEventListener('message', handleMessageEvent);

    // Cleanup para remover el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [assistantId]);

  useEffect(() => {
    const fetchAssistantDetails = async () => {
      const details = await getAssistantDetails(assistantId, apiKey);
      setAssistantDetails(details);

        // Obtener las instrucciones del usuario desde Firestore
  const assistantDocRef = doc(db, 'assistants', assistantId);
  const assistantDoc = await getDoc(assistantDocRef);

  if (assistantDoc.exists()) {
    const assistantData = assistantDoc.data();
    setAssistantDetails({
      ...details,
      userInstructions: assistantData.instructions, // Instrucciones del usuario
      name: assistantData.name, // Asegurarse de usar el nombre correcto
    });
  } else {
    // Si no se encuentran las instrucciones en Firestore
    setAssistantDetails({
      ...details,
      userInstructions: '', // O manejarlo según sea necesario
    });
  }

            // Establece el nombre del asistente en el contexto
            setAssistantName(details.name);
  
      // Verificar si file_search ya está habilitado en OpenAI
      if (details.tools && details.tools.some(tool => tool.type === 'file_search')) {
        setIsKnowledgeBaseEnabled(true); // Sincronizar el estado del interruptor
        if (details.tool_resources && details.tool_resources.file_search.vector_store_ids.length > 0) {
          setSelectedKnowledgeBase(details.tool_resources.file_search.vector_store_ids[0]);
        }
      } else {
        setIsKnowledgeBaseEnabled(false); // Desactivar el interruptor si file_search no está habilitado
      }
    };
  
    const fetchVectorStores = async () => {
      if (!currentUser) {
        console.error('Usuario no autenticado.');
        setVectorStores([]);
        return;
      }
      const vectorStoresData = await getAllVectorStores(currentUser.uid); // Pasar currentUser.uid
      setVectorStores(vectorStoresData);
    };
  
    if (apiKey) { // Asegurarse de que apiKey está disponible
      fetchAssistantDetails();
      fetchVectorStores();
    }
  }, [assistantId, apiKey, setAssistantName, currentUser]);

  const handleSendMessage = async () => {
    if (!input) return;

    const newMessages = [...messages, { role: 'user', content: input }];
    setMessages(newMessages);
    setInput('');
    setIsLoading(true);

    try {
      const assistantResponse = await sendMessage(input, assistantId, threadId, newMessages);
      if (!threadId && assistantResponse && assistantResponse.thread_id) {
        setThreadId(assistantResponse.thread_id);
      }

      // Aquí, assistantResponse.content es un array de mensajes
      setAssistantMessagesQueue(assistantResponse.content);
      processAssistantMessages(assistantResponse.content);
    } catch (error) {
      console.error("Error al enviar mensaje:", error);
      setMessages([...newMessages, { role: 'assistant', content: 'Hubo un error al procesar tu mensaje.' }]);
      setIsLoading(false);
    }
  };

  // Función para procesar y mostrar los mensajes del asistente uno por uno
  const processAssistantMessages = (messagesArray) => {
    if (messagesArray.length === 0) {
      setIsLoading(false);
      return;
    }

    const [firstMessage, ...remainingMessages] = messagesArray;

    setTimeout(() => {
      setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: firstMessage }]);
      processAssistantMessages(remainingMessages);
    }, 1000); // Ajusta el retraso entre mensajes en milisegundos
  };


  const formatMessageContent = (content) => {
    // Remover referencias de fuente como 
    const cleanedContent = content.replace(/【\d+:\d+†.*?】/g, '');

    // Aplicar el formateo existente para negritas, encabezados y saltos de línea
    const formattedContent = cleanedContent
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Negritas
      .replace(/###\s*(.*?)(\n|$)/g, '<h3>$1</h3>')     // Encabezados
      .replace(/\n/g, '<br>');                          // Saltos de línea

    return formattedContent;
  };

  const handleBackToAgents = () => {
    navigate('/');
  };

  const handleTryChatBubble = () => {
    navigate(`/bubble/${assistantId}`);
  };

  const handleKnowledgeBaseToggle = async (e) => {
    const isEnabled = e.target.checked;
    setIsKnowledgeBaseEnabled(isEnabled);
    
    try {
      if (isEnabled) {
        // Habilitar file_search en OpenAI
        await enableFileSearchForAssistant(assistantId, apiKey);

      } else {
        // Deshabilitar file_search en OpenAI
        await disableFileSearchForAssistant(assistantId, apiKey);
        setSelectedKnowledgeBase(''); // Limpiar la selección de base de conocimiento

      }
    } catch (error) {
      console.error('Error actualizando file search:', error);

    }
  };

  const handleKnowledgeBaseChange = async (e) => {
    const vectorStoreId = e.target.value;
    setSelectedKnowledgeBase(vectorStoreId);

    if (vectorStoreId && assistantDetails) {
      try {
        await associateVectorStoreToAssistant(assistantId, vectorStoreId, apiKey);
      
      } catch (error) {
        console.error('Error al asociar la base de conocimiento:', error);
       
      }
    }
  };

  const handleEditInstructions = () => {
    setNewInstructions(assistantDetails.userInstructions);
    setIsEditingInstructions(true);
  };
  

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };
  
    if (isModalOpen) {
      window.addEventListener('keydown', handleEscKey);
    }
  
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isModalOpen]);
  

  const handleSaveInstructions = async () => {
    try {
      await updateAssistantInstructions(assistantId, newInstructions);
      setAssistantDetails({ ...assistantDetails, userInstructions: newInstructions });
      setIsEditingInstructions(false);
  
    } catch (error) {
      console.error('Error al actualizar las instrucciones:', error);

    }
  };
  

  const handleCancelEdit = () => {
    setNewInstructions(assistantDetails.userInstructions);
    setIsEditingInstructions(false);
  };
  
  // Funciones para manejar el modal
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const sessionInfoListener = (event) => {
    if (event.origin == null || !event.origin.endsWith("facebook.com")) {
      return;
    }
  
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          
          handleWhatsAppSignupComplete(phone_number_id, waba_id);
        } else if (data.event === 'ERROR') {
          console.error("Error:", data.data.error_message);
        } else if (data.event === 'CANCEL') {
          console.warn("Canceled at step:", data.data.current_step);
        }
      }
    } catch (error) {
      
    }
  };
  
  useEffect(() => {
    window.addEventListener('message', sessionInfoListener);
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);
  const handleCopyScript = () => {
    if (!assistantId || !passKey) {
     
      console.error('assistantId o passKey faltante:', { assistantId, passKey });
      return;
    }
  
   
    const scriptContent = `
<!-- React -->
<script src="https://unpkg.com/react@17/umd/react.production.min.js" crossorigin></script>

<!-- ReactDOM -->
<script src="https://unpkg.com/react-dom@17/umd/react-dom.production.min.js" crossorigin></script>

<!-- Tu Bundle -->
<script src="https://platform.thethingapp.com/dist/chat-bubble.bundle.js"></script>

     
    <!-- Script para Inicializar el Widget -->
    <script type="text/javascript">
      (function(d, t) {
        var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
        v.onload = function() {
          if (window.ChatBubbleWidget && typeof window.ChatBubbleWidget.load === 'function') {
            window.ChatBubbleWidget.load({
              assistantId: '${assistantId}',
              passKey: '${passKey}',
              showChatIconButton: true
            });
          }
        }; 
        v.src = "https://platform.thethingapp.com/dist/chat-bubble.bundle.js";
        v.type = "text/javascript";
        s.parentNode.insertBefore(v, s);
      })(document, 'script');
    </script>
    `;
  
    navigator.clipboard.writeText(scriptContent)
    .then(() => {
      alert('El código se copió correctamente');
    })
    .catch((err) => {
      console.error('Error al copiar el script:', err);
    });
};

  const handleCopyCustomScript = () => {
    if (!assistantId || !passKey) {
      return;
    }
  
    const customStyles = {
      headerColor,
      bubbleButtonColor,
      messageUserColor,
      messageAssistantColor,
      bubbleButtonImage,
      useBackgroundImage,
      backgroundColor,
      includeWaves: false,
      chatIconButtonColor,  // ← Agregado aquí
    };
    
    const scriptContent = `
    <!-- React -->
    <script src="https://unpkg.com/react@17/umd/react.production.min.js" crossorigin></script>
  
    <!-- ReactDOM -->
    <script src="https://unpkg.com/react-dom@17/umd/react-dom.production.min.js" crossorigin></script>
  
    <!-- Tu Bundle -->
    <script src="https://platform.thethingapp.com/dist/chat-bubble.bundle.js"></script>
  
    <!-- Script para Inicializar el Widget -->
    <script type="text/javascript">
      (function(d, t) {
        var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
        v.onload = function() {
          if (window.ChatBubbleWidget && typeof window.ChatBubbleWidget.load === 'function') {
            window.ChatBubbleWidget.load({
              assistantId: '${assistantId}',
              passKey: '${passKey}',
              customStyles: ${JSON.stringify(customStyles)},
              showChatIconButton: ${showChatIconButton}
            });
          }
        };
        v.src = "https://platform.thethingapp.com/dist/chat-bubble.bundle.js";
        v.type = "text/javascript";
        s.parentNode.insertBefore(v, s);
      })(document, 'script');
    </script>
  `;
  

  navigator.clipboard.writeText(scriptContent)
    .then(() => {
      alert('El código se copió correctamente');
    })
    .catch((err) => {
      console.error('Error al copiar el script:', err);
    });
};
  
  
  
  if (!assistantDetails) return         <div
  className="loading"
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 100px)', 
    fontSize: 'xx-large',
  }}
>
  Cargando...
</div>;

  return (
    <div className='assistant-chat-page'>
      <div className="assistant-page-container">
      <div className="chat-section-container">
        <div className="assistant-editor-container">

        <div className='name-display-agents'>
  {isEditingName ? (
    <div className='name-editor'>
      <input
        type="text"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        className="new-name-input"
      />
      <button onClick={handleSaveName} className="save-newname-button">Guardar</button>
    </div>
  ) : (
    <h2 className='editor-agente' onClick={handleEditName}>
      Editar {assistantDetails.name}
    </h2>
  )}
</div>


          <div className="toggle-container">
            <label className="activar-base">Activar la base de datos</label>
            <label className="switch">
              <input 
                type="checkbox" 
                checked={isKnowledgeBaseEnabled} 
                onChange={handleKnowledgeBaseToggle} 
              />
              <span className="slider round"></span>
            </label>
          </div>

          {isKnowledgeBaseEnabled && (
            <div className='base-selector'>
              <label className="activar-base">Elegir base de datos</label>
              <select className='base-selector' value={selectedKnowledgeBase} onChange={handleKnowledgeBaseChange}>
                <option value="">Select Base</option>
                {vectorStores.map((vs) => (
                  <option key={vs.id} value={vs.id}>
                    {vs.name}
                  </option>
                ))}
              </select>
            </div>
          )}

{/* AssistantChat.js */}

{isEditingInstructions ? (
  <div className='instrucciones'>
    <textarea
      className={`instructions-textarea ${isKnowledgeBaseEnabled ? 'instructions-textarea--active-db' : 'instructions-textarea--inactive-db'}`}
      value={newInstructions}
      onChange={(e) => setNewInstructions(e.target.value)}
      rows={10}
      style={{ width: '100%' }}
    />
  </div>
) : (
  <div className='instrucciones'>
    <textarea
      className={`instructions-textarea ${isKnowledgeBaseEnabled ? 'instructions-textarea--active-db' : 'instructions-textarea--inactive-db'}`}
      value={assistantDetails.userInstructions}
      readOnly
      rows={10}
      style={{ width: '100%' }}
    />
  </div>
)}


          
          {/* Sección de WhatsApp Business */}
          <div className='whatsapp-business'>
             {/* Mostrar el select solo si hay números disponibles */}
  {phoneNumbers && phoneNumbers.length > 0 && (
    <div className="select-and-button">
      <label className="label-number">Número vinculado:</label>
      <select className='number-select' value={selectedPhoneNumber} onChange={handlePhoneNumberChange}>
        {phoneNumbers.map((number) => (
          <option key={number.id} value={number.phone_number_id}>
            {number.phone_number}
          </option>
        ))}
      </select>
    </div>
  )}
          </div>

          {/* Modal */}
          {isModalOpen && (
  <div className="modal-overlay">
    <div className="modal-implementacion" ref={modalRef} tabIndex={-1}>
      <button className="close-button-imp" onClick={handleCloseModal}> x</button>
      <h2 className='implementacion-h2'>Implementación</h2>
      <p className="destino">Seleccione un destino:</p>

      {/* Pestañas */}
      <div className="tabs-container">
        <button
          className={`tab ${activeTab === 'plataformas' ? 'active' : ''}`}
          onClick={() => setActiveTab('plataformas')}
        >
          <img src={require('../assets/iconos-implementacion.png')} alt="Iconos" className="tab-icon" />
        </button>
        <button
          className={`tab ${activeTab === 'codigo' ? 'active' : ''}`}
          onClick={() => setActiveTab('codigo')}
        >
           <img src={require('../assets/codigo-icon.png')} alt="Icon" className="tab-icon" />
          Código
        </button>
        <button
          className={`tab ${activeTab === 'customizar' ? 'active' : ''}`}
          onClick={() => setActiveTab('customizar')}
        >
        <img src={require('../assets/customizar-icon.png')} alt="Icon" className="tab-icon" />
          Customizar
        </button>
      </div>

{/* Contenido de las Pestañas */}
<div className="tab-content">
  {activeTab === 'plataformas' && (
    <div className="platform-buttons">
      <button className="platform-button-whatsapp" onClick={() => setActivePlatform('whatsapp')}>
        <img src={require('../assets/whatsapp-icon.png')} alt="WhatsApp Icon" className="tab-icon" />
        WhatsApp
      </button>

  

  <button className="platform-button disabled-button">
    <img src={require('../assets/instagram-icon.png')} alt="Instagram Icon" className="tab-icon" />
    Instagram
  </button>

  <button className="platform-button disabled-button">
    <img src={require('../assets/messenger-icon.png')} alt="Messenger Icon" className="tab-icon" />
    Messenger
  </button>
</div>

        )}

  {/* Aquí está el ajuste necesario */}
  {activeTab === 'plataformas' && activePlatform === 'whatsapp' && (
    <div className='whatsapp-business'>
      <button className='conect-whatsapp-button' onClick={launchWhatsAppSignup}>
        Conectar WhatsApp
      </button>

{/* Modal para ingresar el PIN */}
{isPinModalOpen && (
  <div className="modal-overlay">
  <div className="modal-pin" ref={modalRef} tabIndex={-1}>
    <button className="close-button" onClick={handleClosePinModal}>X</button>
    <h2 className="modal-title">Ingresa un PIN de 6 dígitos</h2>
    <p className="modal-subtitle">para la autenticación en dos pasos</p>
    
    <input 
      type="text" 
      value={pin} 
      onChange={(e) => setPin(e.target.value)} 
      placeholder="PIN de 6 dígitos" 
      maxLength={6}
      pattern="\d{6}"
      className="pin-input"
    />
    <p className="modal-text">*En cualquier intento de registrar tu número de teléfono en WhatsApp, se solicitará el PIN de seis dígitos que creaste. Si lo olvidas o lo pierdes, puedes desactivar la verificación en dos pasos o cambiar el PIN.</p>
    <div className="modal-buttons">
      <button className='submit-pin-button' onClick={handleSubmitPin} disabled={isSubmitting}>
      {isSubmitting ? 'Enviando...' : 'Enviar Pin'}</button>
      <button className='cancel-pin-button' onClick={handleClosePinModal}>Cancelar</button>
    </div>
  </div>
</div>

)}

    </div>
  )}

  {activeTab === 'codigo' && (
          <>
          <div className="tab-content non-social-tab-content">
            <pre className='code-script'>
              {`
<!-- React -->
<script src="https://unpkg.com/react@17/umd/react.production.min.js" crossorigin></script>

<!-- ReactDOM -->
<script src="https://unpkg.com/react-dom@17/umd/react-dom.production.min.js" crossorigin></script>

<!-- Tu Bundle -->
<script src="https://platform.thethingapp.com/dist/chat-bubble.bundle.js"></script>

        <!-- Script para Inicializar el Widget -->
        <script type="text/javascript">
          (function(d, t) {
            var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
            v.onload = function() {
              if (window.ChatBubbleWidget && typeof window.ChatBubbleWidget.load === 'function') {
                window.ChatBubbleWidget.load({
                  assistantId: '${assistantId}',
                  passKey: '${passKey}',
                });
              } 
            };
            v.src = "https://platform.thethingapp.com/dist/chat-bubble.bundle.js";
            v.type = "text/javascript";
            s.parentNode.insertBefore(v, s);
          })(document, 'script');
        </script> 
              `}
            </pre>
    <div className="button-container">
    <div className="additional-texts">
      <button className='copy-script' onClick={handleCopyScript}>Copiar código</button>
        <p className="info-text">*El código se pega en el header de tu sitio web</p>
       {/*  <p className="help-text">¿No está seguro de dónde implementar su chat web? <br></br><a href="#" target="_blank" rel="noopener noreferrer">Vea este tutorial.</a></p>*/}
      </div>
    </div>
    </div>
          </>
        )}

                {activeTab === 'customizar' && (
                            <div className="tab-content non-social-tab-content">
                  <div className="customization-section">
                    <div className="color-picker-container"> 
                    </div>
                   {/* Color del Encabezado del Chat */}
                   <div className="color-picker-container"> 
                      <p className='form-personalizar'>Color del Encabezado del Chat:</p>
                      <div className="color-picker-inline">
                        <div
                          className="color-swatch"
                          style={{ backgroundColor: headerColor }}
                          onClick={() => setIsHeaderColorPickerOpen(!isHeaderColorPickerOpen)}
                        />
                        <input
                        className='input-custom'
                          type="text"
                          value={headerColor}
                          onChange={(e) => setHeaderColor(e.target.value)}
                        />
                        {isHeaderColorPickerOpen && (
                          <div className="color-picker-popover">
                            <div className="color-picker-cover" onClick={() => setIsHeaderColorPickerOpen(false)} />
                            <SketchPicker
                              color={headerColor}
                              onChangeComplete={(color) => setHeaderColor(color.hex)}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Color del Botón de la Burbuja */}
                    <div className="color-picker-container">
                      <p className='form-personalizar'>Color del Botón de la Burbuja:</p>
                      <div className="color-picker-inline">
                        <div
                          className="color-swatch"
                          style={{ backgroundColor: bubbleButtonColor }}
                          onClick={() => setIsBubbleButtonColorPickerOpen(!isBubbleButtonColorPickerOpen)}
                        />
                        <input
                          type="text"
                          value={bubbleButtonColor}
                          onChange={(e) => setBubbleButtonColor(e.target.value)}
                        />
                        {isBubbleButtonColorPickerOpen && (
                          <div className="color-picker-popover">
                            <div className="color-picker-cover" onClick={() => setIsBubbleButtonColorPickerOpen(false)} />
                            <SketchPicker
                              color={bubbleButtonColor}
                              onChangeComplete={(color) => setBubbleButtonColor(color.hex)}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Color del Botón del Chat */}
<div className="color-picker-container">
  <p className='form-personalizar'>Color del Botón del Chat:</p>
  <div className="color-picker-inline">
    <div
      className="color-swatch"
      style={{ backgroundColor: chatIconButtonColor }}
      onClick={() => setIsChatIconButtonColorPickerOpen(!isChatIconButtonColorPickerOpen)}
    />
    <input
      type="text"
      value={chatIconButtonColor}
      onChange={(e) => setChatIconButtonColor(e.target.value)}
      className='input-custom'
    />
    {isChatIconButtonColorPickerOpen && (
      <div className="color-picker-popover">
        <div className="color-picker-cover" onClick={() => setIsChatIconButtonColorPickerOpen(false)} />
        <SketchPicker
          color={chatIconButtonColor}
          onChangeComplete={(color) => setChatIconButtonColor(color.hex)}
        />
      </div>
    )}
  </div>
</div>


                    {/* Color del Mensaje del Usuario */}
                    <div className="color-picker-container">
                      <p className='form-personalizar'>Color del Mensaje del Usuario:</p>
                      <div className="color-picker-inline">
                        <div
                          className="color-swatch"
                          style={{ backgroundColor: messageUserColor }}
                          onClick={() => setIsMessageUserColorPickerOpen(!isMessageUserColorPickerOpen)}
                        />
                        <input
                          type="text"
                          value={messageUserColor}
                          onChange={(e) => setMessageUserColor(e.target.value)}
                        />
                        {isMessageUserColorPickerOpen && (
                          <div className="color-picker-popover">
                            <div className="color-picker-cover" onClick={() => setIsMessageUserColorPickerOpen(false)} />
                            <SketchPicker
                              color={messageUserColor}
                              onChangeComplete={(color) => setMessageUserColor(color.hex)}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Color del Mensaje del Asistente */}
                    <div className="color-picker-container">
                      <p className='form-personalizar'>Color del Mensaje del Asistente:</p>
                      <div className="color-picker-inline">
                        <div
                          className="color-swatch"
                          style={{ backgroundColor: messageAssistantColor }}
                          onClick={() => setIsMessageAssistantColorPickerOpen(!isMessageAssistantColorPickerOpen)}
                        />
                        <input
                          type="text"
                          value={messageAssistantColor}
                          onChange={(e) => setMessageAssistantColor(e.target.value)}
                        />
                        {isMessageAssistantColorPickerOpen && (
                          <div className="color-picker-popover">
                            <div className="color-picker-cover" onClick={() => setIsMessageAssistantColorPickerOpen(false)} />
                            <SketchPicker
                              color={messageAssistantColor}
                              onChangeComplete={(color) => setMessageAssistantColor(color.hex)}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Opción para usar imagen de fondo predeterminada */}
                    <div className="background-options-container">
                      <label>
                        <input
                          type="checkbox"
                          checked={useBackgroundImage}
                          onChange={(e) => setUseBackgroundImage(e.target.checked)}
                        />
                        Usar imagen de fondo predeterminada
                      </label>
                    </div>

                    {/* Color de fondo del chat (si no se usa imagen predeterminada) */}
                    {!useBackgroundImage && (
                      <div className="color-picker-container">
                        <p className='form-personalizar'>Color de fondo del chat:</p>
                        <div className="color-picker-inline">
                          <div
                            className="color-swatch"
                            style={{ backgroundColor: backgroundColor }}
                            onClick={() => setIsBackgroundColorPickerOpen(!isBackgroundColorPickerOpen)}
                          />
                          <input
                            type="text"
                            value={backgroundColor}
                            onChange={(e) => setBackgroundColor(e.target.value)}
                          />
                          {isBackgroundColorPickerOpen && (
                            <div className="color-picker-popover">
                              <div className="color-picker-cover" onClick={() => setIsBackgroundColorPickerOpen(false)} />
                              <SketchPicker
                                color={backgroundColor}
                                onChangeComplete={(color) => setBackgroundColor(color.hex)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Sección para seleccionar imágenes predeterminadas */}
                    <div className="default-bubble-images">
                      <p className='form-personalizar'>Selecciona una imagen de burbuja predeterminada:</p>
                      <div className="bubble-image-options">
                        <img
                          src={defaultBubbleBlack}
                          alt="Burbuja Negra"
                          className={`bubble-image-option ${bubbleButtonImage === defaultBubbleBlack ? 'selected' : ''}`}
                          onClick={() => setBubbleButtonImage(defaultBubbleBlack)}
                        />
                        <img
                          src={defaultBubbleViolet}
                          alt="Burbuja Violeta"
                          className={`bubble-image-option ${bubbleButtonImage === defaultBubbleViolet ? 'selected' : ''}`}
                          onClick={() => setBubbleButtonImage(defaultBubbleViolet)}
                        />
                        <img
                          src={defaultBubbleWhite}
                          alt="Burbuja Blanca"
                          className={`bubble-image-option ${bubbleButtonImage === defaultBubbleWhite ? 'selected' : ''}`}
                          onClick={() => setBubbleButtonImage(defaultBubbleWhite)}
                        />
                        <img
                          src={defaultBubbleRed}
                          alt="Burbuja Roja"
                          className={`bubble-image-option ${bubbleButtonImage === defaultBubbleRed ? 'selected' : ''}`}
                          onClick={() => setBubbleButtonImage(defaultBubbleRed)}
                        />
                        <img
                          src={defaultBubbleGreen}
                          alt="Burbuja Verde"
                          className={`bubble-image-option ${bubbleButtonImage === defaultBubbleGreen ? 'selected' : ''}`}
                          onClick={() => setBubbleButtonImage(defaultBubbleGreen)}
                        />
                        <img
                          src={defaultBubbleBlue}
                          alt="Burbuja Azul"
                          className={`bubble-image-option ${bubbleButtonImage === defaultBubbleBlue ? 'selected' : ''}`}
                          onClick={() => setBubbleButtonImage(defaultBubbleBlue)}
                        />
                      </div>
                    </div>

                    {/* Opción para subir una imagen personalizada */}
                    <div className="image-upload-container">
                      <p className='cargar-imagen-bubble'>O carga tu propia imagen del Botón de la Burbuja:</p>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              setBubbleButtonImage(reader.result);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                    </div>
                    <div className="toggle-chat-icon-button-container">
  <label>
    <input
      type="checkbox"
      checked={showChatIconButton}
      onChange={(e) => setShowChatIconButton(e.target.checked)}
    />
    Mostrar burbuja de chat web<br />
    (si desactiva esta opción, solo tendrá la burbuja de whatsapp)
  </label>
</div>

                    {/* Botón para generar el script personalizado */}
                    <button className='generate-custom-script' onClick={handleCopyCustomScript}>
                      Generar Código Personalizado
                    </button>
                  </div>
                  </div>
                )}
              </div>
            </div>
          </div>        
        )}

        </div>

        <div className='buttons-training-container'>

        {isEditingInstructions ? (
              <button className='save-instructions-button' onClick={handleSaveInstructions}>GUARDAR</button>
) : (
  <button className='edit-instructions-button' onClick={handleEditInstructions}>Editar Agente</button>
)}

<button className="delete-agent-button" onClick={handleDeleteAssistant}>
          Eliminar Agente
        </button>

          </div>


        </div>      


        <div className="chat-section-container">
        <div className="chat-container">
          <h2 className="chat-header">{assistantDetails.name}</h2>
          <label className="vista-preliminar">Vista preliminar</label>
          <div className="chat-messages">
            {messages.map((message, index) => (
              message.content === assistantDetails.instructions ? null : (

                 <div  key={index} className={`message ${message.role === 'user' ? 'user' : 'assistant' }`} style={{ borderRadius: '20px', paddingTop: '1.3em', paddingBottom: '1.3em', paddingRight: '2em', paddingLeft: '2em', width: 'fit-content' }}>
                  <div
                    className="message-content-training"
                    dangerouslySetInnerHTML={{ __html: formatMessageContent(message.content) }} 
                  />
                </div>
              )
            ))}
            {isLoading && <div className="message assistant"><div className="message-content-training">...</div></div>}
          </div>
          </div>
          <div className="chat-input-container">
  <input 
    type="text" 
    value={input} 
    onChange={(e) => setInput(e.target.value)} 
    placeholder="Escribe tu mensaje" 
    className="chat-input"
    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
  />
  <button onClick={handleSendMessage} className="chat-button" disabled={isLoading}>
    {isMobile ? (
      isLoading ? (
        <div className="spinner-assistant" aria-label="Enviando mensaje"></div>
      ) : (
        <img src={paperClipButton} alt="Enviar" />
      )
    ) : (
      "Enviar"
    )}
  </button>
</div>

        </div>
      </div>
      <div className="back-button-container">
        <button onClick={handleTryChatBubble}  className='edit-instructions-button'>
            Probar bubble chat
          </button>

          {/* Botón para abrir el modal */}
          <button onClick={handleOpenModal}  className='save-instructions-button'>
          Implementar en redes/website
          </button>
      </div>
    </div>
  );
}; 

export default AssistantChat; 
