// src/context/SelectedTeamMemberContext.js
import React, { createContext, useState } from 'react';

export const SelectedTeamMemberContext = createContext();

export const SelectedTeamMemberProvider = ({ children }) => {
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  return (
    <SelectedTeamMemberContext.Provider value={{ selectedTeamMember, setSelectedTeamMember }}>
      {children}
    </SelectedTeamMemberContext.Provider>
  );
};
