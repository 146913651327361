// src/components/WideSidebar.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './WideSidebar.css';
import { useAuth } from '../context/AuthContext';
import { collection, doc, onSnapshot, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { getAllAssistants } from '../services/openaiService';
import { SearchContext } from '../context/SearchContext';
import { SelectedTeamMemberContext } from '../context/SelectedTeamMemberContext';
import useTeamMembers from '../hooks/useTeamMembers';
import { db } from '../firebase';

const WideSidebar = ({ isVisible, crmColumns }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { setSearchTerm } = useContext(SearchContext);
  const { selectedTeamMember, setSelectedTeamMember } = useContext(SelectedTeamMemberContext);

  const isThreadsPage = location.pathname.startsWith('/messages');
  const isChatPage = location.pathname.startsWith('/chat');
  const isCrmPage = location.pathname === '/crm';
  const isAnalyticsPage = location.pathname === '/analytics' || location.pathname === '/support';
  const isProfilePage = location.pathname === '/profile';

  const bubbleMatch = location.pathname.match(/^\/bubble\/([^/]+)/);
  const isBubblePage = !!bubbleMatch;
  const bubbleAssistantId = isBubblePage ? bubbleMatch[1] : null;

  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assistantThreadCounts, setAssistantThreadCounts] = useState({});

  const { teamMembers, teamMembersWithCounts } = useTeamMembers(currentUser, isThreadsPage);
  const unsubscribeAssistantsListenersRef = useRef([]);

  // Estado global para auto-respuesta
  const [globalAutoResponseEnabled, setGlobalAutoResponseEnabled] = useState(true);

  // Al montar, leemos la preferencia global en Firestore
  useEffect(() => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);

    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (typeof data.globalAutoResponseEnabled === 'boolean') {
          setGlobalAutoResponseEnabled(data.globalAutoResponseEnabled);
        } else {
          setGlobalAutoResponseEnabled(true);
        }
      }
    });

    return () => unsubscribe();
  }, [currentUser]);

  const handleToggleGlobalAutoResponse = async () => {
    const newValue = !globalAutoResponseEnabled;
    setGlobalAutoResponseEnabled(newValue);

    if (currentUser) {
      const docRef = doc(db, 'users', currentUser.uid);
      try {
        await updateDoc(docRef, {
          globalAutoResponseEnabled: newValue
        });
      } catch (error) {
        console.error('Error al actualizar globalAutoResponseEnabled:', error);
      }
    }
  };

  const getConversationOwnerVariants = (nombre, apellido) => {
    const baseName = `${nombre} ${apellido}`.trim();
    return [
      baseName,
      `${baseName} `
    ];
  };

  const fetchConversationCounts = async (members, assistants) => {
    const counts = {};
    for (const member of members) {
      const ownerVariants = getConversationOwnerVariants(member.nombre, member.apellido);
      let memberCount = 0;

      for (const assistant of assistants) {
        const threadsCollection = collection(db, `threads_${assistant.id}`);
        const q = query(threadsCollection, where('conversationowner', 'in', ownerVariants));
        try {
          const querySnapshot = await getDocs(q);
          memberCount += querySnapshot.size;
        } catch (error) {
          console.error(`Error al contar threads para ${member.nombre} en ${assistant.id}:`, error);
        }
      }
      counts[member.id] = memberCount;
    }
    return counts;
  };

  useEffect(() => {
    const fetchAssistants = async () => {
      if (currentUser && currentUser.uid) {
        setLoading(true);
        try {
          const assistantsData = await getAllAssistants(currentUser.uid);
          setAssistants(assistantsData);
        } catch (error) {
          console.error('Error al obtener asistentes:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAssistants();
  }, [currentUser]);

  useEffect(() => {
    if (agentSearchTerm.trim() === '') {
      setFilteredAgents(assistants);
    } else {
      setFilteredAgents(
        assistants.filter((agent) =>
          agent.name.toLowerCase().includes(agentSearchTerm.toLowerCase())
        )
      );
    }
  }, [assistants, agentSearchTerm]);

  useEffect(() => {
    if (!isThreadsPage || assistants.length === 0) return;

    unsubscribeAssistantsListenersRef.current.forEach((unsubscribe) => unsubscribe());
    unsubscribeAssistantsListenersRef.current = [];

    assistants.forEach((assistant) => {
      const threadsCollectionRef = collection(db, `threads_${assistant.id}`);
      const unsubscribe = onSnapshot(
        threadsCollectionRef,
        (querySnapshot) => {
          setAssistantThreadCounts((prevCounts) => ({
            ...prevCounts,
            [assistant.id]: querySnapshot.size,
          }));
        },
        (error) => {
          console.error(`Error en el listener de threads_${assistant.id}:`, error);
          setAssistantThreadCounts((prevCounts) => ({
            ...prevCounts,
            [assistant.id]: 0,
          }));
        }
      );
      unsubscribeAssistantsListenersRef.current.push(unsubscribe);
    });

    return () => {
      unsubscribeAssistantsListenersRef.current.forEach((unsubscribe) => unsubscribe());
      unsubscribeAssistantsListenersRef.current = [];
    };
  }, [isThreadsPage, assistants, db]);

  useEffect(() => {
    const updateTeamMembersWithCounts = async () => {
      if (!currentUser || !isThreadsPage) return;

      try {
        const assistantsData = assistants;
        if (assistantsData.length === 0) return;
        const members = teamMembers;
        const conversationCounts = await fetchConversationCounts(members, assistantsData);
        // Podrías guardar esa info en un estado local o en tu store
      } catch (error) {
        console.error('Error al actualizar conteos de conversaciones:', error);
      }
    };
    updateTeamMembersWithCounts();
  }, [teamMembers, assistants, currentUser, isThreadsPage]);

  const handleAgentSearch = (term) => {
    setAgentSearchTerm(term);
  };

  const handleSelectAgent = (agent) => {
    setSelectedTeamMember(null);
    navigate(`/messages/${agent.id}`);
  };

  const handleChatAgentSelection = (assistantId) => {
    navigate(`/chat/${assistantId}`);
  };

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSelectTeamMemberClick = (member) => {
    setSelectedTeamMember(member);
    navigate(`/messages`);
  };

  return (
    <div className={`wide-sidebar ${isVisible ? 'visible' : 'hidden'}`}>
      {isBubblePage ? (
        <div className="wide-sidebar-content">
          <h2>Prueba tu agente</h2>
          <ul className="scrollable-list">
            <li
              className="list-item"
              onClick={() => navigate(`/chat/${bubbleAssistantId}`)}
            >
              Volver al entrenamiento
            </li>
          </ul>
        </div>
      ) : (
        <>
          {isThreadsPage ? (
            <div className="wide-sidebar-content">
              <h2 className="titulos-threads">Mis agentes</h2>

          

              <div className="search-container">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Buscar"
                  value={agentSearchTerm}
                  onChange={(e) => setAgentSearchTerm(e.target.value)}
                />
              </div>
              <ul className="scrollable-list">
                {loading ? (
                  <li className="list-item">Cargando...</li>
                ) : filteredAgents.length > 0 ? (
                  filteredAgents.map((agent) => (
                    <li
                      key={agent.id}
                      className="list-item"
                      onClick={() => {
                        setSelectedTeamMember(null);
                        navigate(`/messages/${agent.id}`);
                      }}
                    >
                      <div className="agent-info">
                        <p className="agent-name">{agent.name}</p>
                        <span className="thread-count">
                          {assistantThreadCounts[agent.id] || 0}
                        </span>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="list-item">No se encontraron agentes</li>
                )}

                {teamMembersWithCounts.length > 0 ? (
                  teamMembersWithCounts.map((member) => (
                    <li
                      key={`member-${member.id}`}
                      className="list-item"
                      onClick={() => {
                        setSelectedTeamMember(member);
                        navigate(`/messages`);
                      }}
                    >
                      <div className="agent-info">
                        <p className="agent-name">{`${member.nombre} ${member.apellido}`}</p>
                        <span className="thread-count">
                          {member.conversationCount}
                        </span>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="list-item">No hay miembros con conversaciones</li>
                )}
                
              </ul>
                  {/* Interruptor Global SOLO en /messages */}
                  <div className="global-autoresponse-switch-container">
                <span className="global-autoresponse-label">Respuesta IA Global</span>
                <label className="switch-ia-global">
                  <input
                    type="checkbox"
                    checked={globalAutoResponseEnabled}
                    onChange={handleToggleGlobalAutoResponse}
                  />
                  <span className="slider pending-slider"></span>
                </label>
              </div>
            </div>
          ) : isChatPage ? (
            <div className="wide-sidebar-content">
              <h2>Entrenador <br /> de Agentes</h2>
              <ul className="scrollable-list">
                {loading ? (
                  <li className="list-item">Cargando...</li>
                ) : assistants.length > 0 ? (
                  assistants.map((assistant) => (
                    <li
                      key={assistant.id}
                      className="list-item"
                      onClick={() => navigate(`/chat/${assistant.id}`)}
                    >
                      {assistant.name}
                    </li>
                  ))
                ) : (
                  <li className="list-item">No hay agentes disponibles</li>
                )}
              </ul>
            </div>
          ) : isCrmPage ? (
            <div className="wide-sidebar-content">
              <h2>Panel de <br />seguimiento</h2>
              <div className="search-container">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Buscar por nombre o teléfono"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          ) : isAnalyticsPage ? (
            <div className="wide-sidebar-content">
              <h2>Próximamente</h2>
            </div>
          ) : isProfilePage ? (
            <div className="wide-sidebar-content">
              <h2>Perfil</h2>
              <ul className="scrollable-list">
                <li
                  className="list-item"
                  onClick={() => {
                    document.getElementById('conversations-counter')
                      ?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Conversaciones
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    document.getElementById('subscription-counter')
                      ?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Suscripción
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    document.getElementById('add-member-button')
                      ?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Equipo
                </li>
              </ul>
            </div>
          ) : (
            <div className="wide-sidebar-content">
              <h2>Comenzar <br /> The Thing</h2>
              <ul className="scrollable-list">
                <li className="list-item">Mis Agentes</li>
                <li className="list-item">Mis Bases de Datos</li>
                <li className="list-item">Tutorial</li>
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WideSidebar;
