// src/components/WideSidebarMobile.jsx

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './WideSidebarMobile.css';
import { useAuth } from '../context/AuthContext';
import { collection, doc, onSnapshot, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { getAllAssistants } from '../services/openaiService';
import { SearchContext } from '../context/SearchContext';
import { SelectedTeamMemberContext } from '../context/SelectedTeamMemberContext';
import useTeamMembers from '../hooks/useTeamMembers'; // Custom Hook para teamMembers
import { db } from '../firebase';

const WideSidebarMobile = ({ isVisible, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { setSearchTerm } = useContext(SearchContext);
  const { selectedTeamMember, setSelectedTeamMember } = useContext(SelectedTeamMemberContext);

  // Determinar la ruta actual
  const isThreadsPage = location.pathname.startsWith('/messages');
  const isChatPage = location.pathname.startsWith('/chat');
  const isCrmPage = location.pathname === '/crm';
  const isProfilePage = location.pathname === '/profile';
  const isAnalyticsPage = location.pathname === '/analytics' || location.pathname === '/support';

  // Detectar si la ruta es /bubble/assistantId
  const bubbleMatch = location.pathname.match(/^\/bubble\/([^/]+)/);
  const isBubblePage = !!bubbleMatch;
  const bubbleAssistantId = isBubblePage ? bubbleMatch[1] : null;

  // Estados para búsqueda y datos de asistentes
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assistantThreadCounts, setAssistantThreadCounts] = useState({});

  // Custom Hook para manejar teamMembers y teamMembersWithCounts
  const { teamMembers, teamMembersWithCounts } = useTeamMembers(currentUser, isThreadsPage);

  // Referencia para los listeners de asistentes
  const unsubscribeAssistantsListenersRef = useRef([]);

  // ─────────────────────────────────────────────────────────
  // 1) Estado global para auto-respuesta (igual que en WideSidebar)
  // ─────────────────────────────────────────────────────────
  const [globalAutoResponseEnabled, setGlobalAutoResponseEnabled] = useState(true);

  useEffect(() => {
    // Solo necesitamos escuchar Firestore si el usuario está logueado
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (typeof data.globalAutoResponseEnabled === 'boolean') {
          setGlobalAutoResponseEnabled(data.globalAutoResponseEnabled);
        } else {
          setGlobalAutoResponseEnabled(true);
        }
      }
    });
    return () => unsubscribe();
  }, [currentUser]);

  // Función para cambiar la respuesta IA global
  const handleToggleGlobalAutoResponse = async () => {
    const newValue = !globalAutoResponseEnabled;
    setGlobalAutoResponseEnabled(newValue);

    if (currentUser) {
      const docRef = doc(db, 'users', currentUser.uid);
      try {
        await updateDoc(docRef, {
          globalAutoResponseEnabled: newValue
        });
      } catch (error) {
        console.error('Error al actualizar globalAutoResponseEnabled:', error);
      }
    }
  };

  // Auxiliares para conteo
  const getConversationOwnerVariants = (nombre, apellido) => {
    const baseName = `${nombre} ${apellido}`.trim();
    return [baseName, `${baseName} `];
  };

  const fetchConversationCounts = async (members, assistants) => {
    const counts = {};
    for (const member of members) {
      const ownerVariants = getConversationOwnerVariants(member.nombre, member.apellido);
      let memberCount = 0;
      for (const assistant of assistants) {
        const threadsCollection = collection(db, `threads_${assistant.id}`);
        const q = query(threadsCollection, where('conversationowner', 'in', ownerVariants));
        try {
          const querySnapshot = await getDocs(q);
          memberCount += querySnapshot.size;
        } catch (error) {
          console.error(`Error al contar threads para ${member.nombre} en ${assistant.id}:`, error);
        }
      }
      counts[member.id] = memberCount;
    }
    return counts;
  };

  // useEffect para obtener los asistentes
  useEffect(() => {
    const fetchAssistants = async () => {
      if (currentUser && currentUser.uid) {
        setLoading(true);
        try {
          const assistantsData = await getAllAssistants(currentUser.uid);
          setAssistants(assistantsData);
        } catch (error) {
          console.error('Error al obtener asistentes:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAssistants();
  }, [currentUser]);

  // Filtrado de agentes según la búsqueda
  useEffect(() => {
    if (agentSearchTerm.trim() === '') {
      setFilteredAgents(assistants);
    } else {
      setFilteredAgents(
        assistants.filter((agent) =>
          agent.name.toLowerCase().includes(agentSearchTerm.toLowerCase())
        )
      );
    }
  }, [assistants, agentSearchTerm]);

  // Listener en tiempo real para threads (solo si isThreadsPage)
  useEffect(() => {
    if (!isThreadsPage || assistants.length === 0) return;

    unsubscribeAssistantsListenersRef.current.forEach((unsubscribe) => unsubscribe());
    unsubscribeAssistantsListenersRef.current = [];

    assistants.forEach((assistant) => {
      const threadsCollectionRef = collection(db, `threads_${assistant.id}`);
      const unsubscribe = onSnapshot(
        threadsCollectionRef,
        (querySnapshot) => {
          setAssistantThreadCounts((prevCounts) => ({
            ...prevCounts,
            [assistant.id]: querySnapshot.size,
          }));
        },
        (error) => {
          console.error(`Error en el listener de threads_${assistant.id}:`, error);
          setAssistantThreadCounts((prevCounts) => ({
            ...prevCounts,
            [assistant.id]: 0,
          }));
        }
      );
      unsubscribeAssistantsListenersRef.current.push(unsubscribe);
    });

    return () => {
      unsubscribeAssistantsListenersRef.current.forEach((unsubscribe) => unsubscribe());
      unsubscribeAssistantsListenersRef.current = [];
    };
  }, [isThreadsPage, assistants]);

  // useEffect para actualizar conteo de conversaciones por miembro
  useEffect(() => {
    const updateTeamMembersWithCounts = async () => {
      if (!currentUser || !isThreadsPage) return;
      try {
        if (assistants.length === 0) return;
        const members = teamMembers;
        const conversationCounts = await fetchConversationCounts(members, assistants);
        // Podrías actualizar algún estado local con esos conteos.
        // Por ejemplo, si tu hook "useTeamMembers" ya lo hace, genial.
      } catch (error) {
        console.error('Error al actualizar conteos de conversaciones:', error);
      }
    };
    updateTeamMembersWithCounts();
  }, [teamMembers, assistants, currentUser, isThreadsPage]);

  // Handlers para selección
  const handleAgentSearch = (term) => {
    setAgentSearchTerm(term);
  };

  const handleSelectAgent = (agent) => {
    setSelectedTeamMember(null);
    navigate(`/messages/${agent.id}`);
    onClose();
  };

  const handleChatAgentSelection = (assistantId) => {
    navigate(`/chat/${assistantId}`);
    onClose();
  };

  const handleSelectTeamMemberClick = (member) => {
    setSelectedTeamMember(member);
    navigate(`/messages`);
    onClose();
  };

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    onClose();
  };

  if (isBubblePage) {
    // Si la ruta es /bubble/assistantId => sólo mostramos "Volver al entrenamiento"
    return (
      <div className={`wide-sidebar-mobile ${isVisible ? 'visible' : 'hidden'}`}>
        <div className="wide-sidebar-mobile-content">
          <h2>Agente Personalizado</h2>
          <ul className="scrollable-list">
            <li
              className="list-item-mobile"
              onClick={() => {
                navigate(`/chat/${bubbleAssistantId}`);
                onClose();
              }}
            >
              Volver al entrenamiento
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className={`wide-sidebar-mobile ${isVisible ? 'visible' : 'hidden'}`}>
      {isThreadsPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2 className="titulos-threads-mobile">Mis agentes</h2>

          {/* Campo de búsqueda */}
          <div className="search-container">
            <input
              type="text"
              className="search-input-mobile"
              placeholder="Buscar"
              value={agentSearchTerm}
              onChange={(e) => handleAgentSearch(e.target.value)}
            />
          </div>

          {/* Lista de Asistentes */}
          <ul className="scrollable-list">
            {loading ? (
              <li className="list-item-mobile">Cargando...</li>
            ) : filteredAgents.length > 0 ? (
              filteredAgents.map((agent) => (
                <li
                  key={agent.id}
                  className="list-item-mobile"
                  onClick={() => handleSelectAgent(agent)}
                >
                  <div className="agent-info">
                    <p className="agent-name">{agent.name}</p>
                    <span className="thread-count">
                      {assistantThreadCounts[agent.id] || 0}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="list-item-mobile">No se encontraron agentes</li>
            )}

            {/* Lista de Miembros del Equipo con su "conversationCount" */}
            {teamMembersWithCounts && teamMembersWithCounts.length > 0 ? (
              teamMembersWithCounts.map((member) => (
                <li
                  key={`member-${member.id}`}
                  className="list-item-mobile"
                  onClick={() => handleSelectTeamMemberClick(member)}
                >
                  <div className="agent-info">
                    <p className="agent-name">
                      {`${member.nombre} ${member.apellido}`}
                    </p>
                    <span className="thread-count">
                      {member.conversationCount || 0}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="list-item-mobile">
                No hay miembros con conversaciones
              </li>
            )}
          </ul>

          {/* 2) Botón / Switch para detener la IA global */}
          <div className="global-autoresponse-switch-container-mobile">
  <span className="global-autoresponse-label-mobile">
    Respuesta IA Global
  </span>
  <label className="switch-ia-global-mobile">
    <input
      type="checkbox"
      checked={globalAutoResponseEnabled}
      onChange={handleToggleGlobalAutoResponse}
    />
    <span className="slider-mobile"></span>
  </label>
</div>

        </div>
      ) : isChatPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Entrenador de Agentes</h2>
          <ul className="scrollable-list">
            {loading ? (
              <li className="list-item-mobile">Cargando...</li>
            ) : assistants.length > 0 ? (
              assistants.map((assistant) => (
                <li
                  key={assistant.id}
                  className="list-item-mobile"
                  onClick={() => handleChatAgentSelection(assistant.id)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleChatAgentSelection(assistant.id);
                    }
                  }}
                >
                  {assistant.name}
                </li>
              ))
            ) : (
              <li className="list-item-mobile">No hay agentes disponibles</li>
            )}
          </ul>
        </div>
      ) : isCrmPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Panel de seguimiento</h2>
          <div className="search-container">
            <input
              type="text"
              className="search-input-mobile"
              placeholder="Buscar por nombre o teléfono"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      ) : isAnalyticsPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Próximamente</h2>
        </div>
      ) : isProfilePage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Perfil</h2>
          <ul className="scrollable-list">
            <li
              className="list-item-mobile"
              onClick={() => {
                document.getElementById('conversations-counter')
                  ?.scrollIntoView({ behavior: 'smooth' });
                onClose();
              }}
            >
              Conversaciones
            </li>
            <li
              className="list-item-mobile"
              onClick={() => {
                document.getElementById('subscription-counter')
                  ?.scrollIntoView({ behavior: 'smooth' });
                onClose();
              }}
            >
              Suscripción
            </li>
            <li
              className="list-item-mobile"
              onClick={() => {
                document.getElementById('add-member-button')
                  ?.scrollIntoView({ behavior: 'smooth' });
                onClose();
              }}
            >
              Equipo
            </li>
          </ul>
        </div>
      ) : (
        <div className="wide-sidebar-mobile-content">
          <h2>Comenzar The Thing</h2>
          <ul className="scrollable-list">
            <li
              className="list-item-mobile"
              onClick={() => scrollToSection('misagentes')}
            >
              MIS AGENTES
            </li>
            <li
              className="list-item-mobile"
              onClick={() => scrollToSection('basesdedatos')}
            >
              MIS BASES DE DATOS
            </li>
            <li
              className="list-item-mobile"
              onClick={() => scrollToSection('tutorial-section')}
            >
              TUTORIAL
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default WideSidebarMobile;
