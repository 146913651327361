// src/hooks/useTeamMembers.js
import { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAllAssistants } from '../services/openaiService';
import { useAuth } from '../context/AuthContext';

const useTeamMembers = (currentUser, isMessagesPage) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersWithCounts, setTeamMembersWithCounts] = useState([]);
  const unsubscribeMembersListenersRef = useRef([]);
  const assistantOwnerCountsRef = useRef({});

  const { currentUser: authUser } = useAuth(); // Asegúrate de obtener el usuario actual correctamente

  useEffect(() => {
    if (!currentUser) return;

    const fetchTeamMembers = async () => {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          console.error('Documento del usuario no encontrado.');
          return;
        }

        const userData = userDoc.data();

        // Miembros ficticios
        const fictitiousMembers = userData.equipo || [];

        // Miembros reales
        let accessCodeMembers = [];
        const accessCode = userData.accessCode;
        if (accessCode) {
          const usersRef = collection(db, 'users');
          const q = query(usersRef, where('accessCode', '==', accessCode));
          const usersSnapshot = await getDocs(q);

          accessCodeMembers = usersSnapshot.docs
            .filter((docSnapshot) => docSnapshot.id !== currentUser.uid)
            .map((docSnapshot) => ({
              id: docSnapshot.id,
              nombre: docSnapshot.data().name,
              apellido: docSnapshot.data().apellido || '',
              cargo: docSnapshot.data().cargo || '',
            }));
        }

        const combinedTeamMembers = [
          // Asignar un ID único a cada miembro ficticio
          ...fictitiousMembers.map((member, index) => ({
            id: `fictitious-${index}`, // ID único para fictitious
            type: 'fictitious',
            nombre: member.nombre,
            apellido: member.apellido,
            cargo: member.cargo,
          })),
          // Miembros reales con ID obtenido de Firestore
          ...accessCodeMembers.map((member) => ({
            id: member.id, // ID obtenido de Firestore
            type: 'real',
            nombre: member.nombre,
            apellido: member.apellido,
            cargo: member.cargo,
          })),
          // Incluir al usuario actual con su UID como ID
          {
            id: currentUser.uid, // ID único basado en UID
            type: 'real',
            nombre: userData.name || userData.nombre || 'Usuario', // Ajusta según tus campos
            apellido: userData.apellido || '',
            cargo: userData.cargo || '',
          },
        ];

        setTeamMembers(combinedTeamMembers);
      } catch (error) {
        console.error('Error al obtener miembros del equipo:', error);
      }
    };

    fetchTeamMembers();
  }, [currentUser]);

  useEffect(() => {
    if (!isMessagesPage || teamMembers.length === 0) {
      setTeamMembersWithCounts([]);
      return;
    }

    // Limpiar listeners anteriores
    unsubscribeMembersListenersRef.current.forEach((unsubscribe) => unsubscribe());
    unsubscribeMembersListenersRef.current = [];

    assistantOwnerCountsRef.current = {};

    const fetchConversationCounts = async () => {
      try {
        const assistantsData = await getAllAssistants(currentUser.uid);
        for (const assistant of assistantsData) {
          for (const member of teamMembers) {
            const ownerName = `${member.nombre} ${member.apellido}`.trim();
            const ownerVariants = [
              ownerName,
              `${ownerName} `
            ];

            const threadsCollectionRef = collection(db, `threads_${assistant.id}`);
            const q = query(threadsCollectionRef, where('conversationowner', 'in', ownerVariants));

            const unsubscribe = onSnapshot(
              q,
              (querySnapshot) => {
                const newCountsForThisAssistant = assistantOwnerCountsRef.current[assistant.id] || {};

                // Actualizar el conteo para el miembro específico
                newCountsForThisAssistant[ownerName] = querySnapshot.size;

                // Actualizar el objeto de conteos
                assistantOwnerCountsRef.current[assistant.id] = newCountsForThisAssistant;

                // Combinar todos los asistentes
                const combinedCounts = {};
                for (const aId in assistantOwnerCountsRef.current) {
                  const assistantCounts = assistantOwnerCountsRef.current[aId];
                  for (const ownerNameKey in assistantCounts) {
                    combinedCounts[ownerNameKey] = (combinedCounts[ownerNameKey] || 0) + assistantCounts[ownerNameKey];
                  }
                }

                // Crear array de teamMembersWithCounts
                const membersWithCounts = teamMembers
                  .map((member) => {
                    const ownerName = `${member.nombre} ${member.apellido}`.trim();
                    return {
                      ...member,
                      conversationCount: combinedCounts[ownerName] || 0,
                    };
                  })
                  .filter((member) => member.conversationCount > 0);

                setTeamMembersWithCounts(membersWithCounts);

              },
              (error) => {
                console.error(`Error en el listener de threads_${assistant.id}:`, error);
              }
            );

            unsubscribeMembersListenersRef.current.push(unsubscribe);
          }
        }
      } catch (error) {
        console.error('Error al configurar listeners para conversaciones:', error);
      }
    };

    fetchConversationCounts();

    return () => {
      unsubscribeMembersListenersRef.current.forEach((unsubscribe) => unsubscribe());
      unsubscribeMembersListenersRef.current = [];
    };
  }, [isMessagesPage, teamMembers, currentUser]);

  return { teamMembers, teamMembersWithCounts };
};

export default useTeamMembers;
