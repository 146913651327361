import React, { useState, useEffect } from 'react';
import { createAssistantInFirestore, createAssistantInOpenAI } from '../services/openaiService';
import { db } from '../firebase';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const CreateAssistant = ({ prefilledData, onAssistantCreated, teamMembers = [] }) => { 
  const [name, setName] = useState('');
  
  // Múltiples campos para las “instrucciones”
  const [field1, setField1] = useState(''); 
  const [field2, setField2] = useState(''); 
  const [field3, setField3] = useState(''); 
  const [field4, setField4] = useState(''); 
  const [field5, setField5] = useState('');  // Nuevo: "Flujo y objetivo conversacional"
  const [field6, setField6] = useState('');  // Instrucciones extra (opcional)

  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  
  // Texto por defecto para llenar cada campo individual.
  // Puedes personalizarlo como gustes:
  const defaultField1 = `Sos Techi, asistente de soporte técnico en TechSolutions. Tu labor principal es ayudar a las personas que te hablen a resolver dudas técnicas y guiarlas en el uso de nuestros productos y servicios.`;
  const defaultField2 = `Manten un tono cercano, amable y claro. Busca que cualquier persona, sea técnica o no, pueda entender las explicaciones sin problemas. Utiliza un lenguaje sencillo, pero profesional.`;
  const defaultField3 = `Representas a TechSolutions, una empresa dedicada al desarrollo de soluciones de software y hardware para pequeñas y medianas empresas.
Servicios que ofrecemos: Instalación y configuración de software propio de TechSolutions, soporte en línea para problemas de funcionamiento, y orientación para implementar nuestras soluciones en sus procesos de negocio.
Servicios que NO ofrecemos: Soporte para software o hardware que no esté relacionado con TechSolutions (por ejemplo, no ofrecemos servicio de diseño gráfico, marketing digital ni atención a proveedores externos).`;
  const defaultField4 = `No compartir nunca información interna confidencial de la compañía (códigos de activación, accesos de administrador) ni datos personales de empleados o clientes. Evitar recomendaciones ilegales o inseguras. En caso de preguntas que no correspondan a nuestra oferta de productos o servicios, debes redirigir la conversación a un canal adecuado o bien indicar que no puedes ayudar con ese tema.`;
  const defaultField5 = `El objetivo principal es resolver las dudas técnicas de los usuarios y, en caso necesario, guiarlos para agendar una sesión de soporte remota o instalar nuestro software.
1: Saludo y presentación (pedir nombre y correo electrónico para identificar el caso).
2: Identificar problema o duda (escuchar atentamente la descripción del usuario).
3: Proponer solución (si es un problema simple, ofrecer pasos para solucionarlo; si es más complejo, invitarlo a agendar sesión).
4: Confirmar si se resuelve (preguntar si la explicación fue útil o si hay alguna duda adicional).
5: Finalizar (si el cliente está conforme, despedirse cordialmente y reiterar la disponibilidad para futuras consultas).`;
  const defaultField6 = `Si el problema es complejo o requiere revisar configuraciones muy específicas, ofrecer un link de soporte remoto. Si el usuario lo solicita, enviar un manual de configuración por correo electrónico. Felicitar al usuario cuando complete un proceso exitosamente o logre instalar la solución por su cuenta.`;

  const saveAssistantImage = async (assistantId, image) => {
    const userDocRef = doc(db, 'assistants', assistantId);
    await setDoc(userDocRef, { image }, { merge: true });
  };

  // Si se reciben datos para pre-cargar (por ejemplo, en modo edición), podemos parsearlos aquí
  useEffect(() => {
    if (prefilledData) {
      setName(prefilledData.name || '');
      
      // Aquí podrías parsear `prefilledData.instructions` en los 5 campos.
      // Ejemplo ultra simplificado (separando por un delimitador imaginario):
      // const parts = prefilledData.instructions.split('---DELIMITER---');
      // setField1(parts[0] || '');
      // setField2(parts[1] || '');
      // ... etc.
      // Ajusta a tu caso real.
    }
  }, [prefilledData]);

  const handleUseDefaultText = () => {
    setField1(defaultField1);
    setField2(defaultField2);
    setField3(defaultField3);
    setField4(defaultField4);
    setField5(defaultField5);
    setField6(defaultField6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    // Asegurarnos de agregar al "allowedUsers" a todos los miembros del equipo + el currentUser
    const allowedUsers = Array.isArray(teamMembers) 
      ? teamMembers.map(member => member.id).concat(currentUser.uid) 
      : [currentUser.uid];

    // Concatenar todos los campos en un solo bloque de instrucciones,
    // incluyendo siempre el prompt oculto (hiddenPrompt).
    const finalInstructions = `
=== CAMPO 1 (Presentación y rol) ===
${field1}

=== CAMPO 2 (Tono) ===
${field2}

=== CAMPO 3 (Contexto) ===
${field3}

=== CAMPO 4 (Prohibiciones) ===
${field4}

=== CAMPO 5 (Flujo y objetivo conversacional) ===
${field5}

=== CAMPO 6 (Instrucciones extra) ===
${field6}

`.trim();

    const assistantData = {
      name,
      instructions: finalInstructions, // Se envía como un solo bloque
      allowedUsers,
      model: 'gpt-4o-mini-2024-07-18',
      tools: [],
      tool_resources: {},
      createdAt: serverTimestamp(),
    };

    try {
      // Crear asistente en OpenAI (o donde corresponda)
      const newAssistant = await createAssistantInOpenAI(assistantData);

      if (newAssistant) {
        // Guardar en Firestore
        await createAssistantInFirestore(assistantData, newAssistant.id);

        // Si hay imagen en prefilledData, también la guardamos
        if (prefilledData && prefilledData.image) {
          await saveAssistantImage(newAssistant.id, prefilledData.image);
        }

        onAssistantCreated();
        navigate('/');
      }
    } catch (error) {
      console.error('Error al crear el asistente:', error);
      alert('Error al crear el asistente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2 className='crear-asistente'>Nuevo Agente</h2>
      
      <p className='nombre-asistente'>
        Nombre del agente <span className="required">*</span>
      </p>
      <form onSubmit={handleSubmit} className="auth-form">
        <input
          id="name"
          type="text" 
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Agente de soporte técnico"
          className="crear-asistente-input"
          required
        />

        {/** Campo 1 */}
        <p className='nombre-asistente'>
          1. Presentación (Nombre y rol)
        </p>
        <textarea
          className='instrucciones-textarea'
          value={field1}
          onChange={(e) => setField1(e.target.value)}
          rows={2}
          placeholder="Explica a tu agente cómo debe presentarse, cúal será su nombre y su rol..."
        />

        {/** Campo 2 */}
        <p className='asistente'>
          2. Tono de respuesta
        </p>
        <textarea
          className='instrucciones-textarea'
          value={field2}
          onChange={(e) => setField2(e.target.value)}
          rows={2}
          placeholder="Respuestas formales, cortas y precisas; Respuestas informales..."
        />

        {/** Campo 3 */}
        <p className='asistente'>
          3. Contexto (A quién representa, servicios que ofrecerá y NO ofrecerá)
        </p>
        <textarea
          className='instrucciones-textarea'
          value={field3}
          onChange={(e) => setField3(e.target.value)}
          rows={5}
          placeholder={
            "Explica el contexto: a quién representa (empresa, persona), qué servicios ofrecerá y qué NO ofrecerá.\n\n" +
            "Ej: Representas a Impulso Creativo, una empresa de marketing digital, ofreces campañas de publicidad digital, diseño y desarrollo web... NO ofreces administración de redes sociales, NO ofreces atención al cliente..."
          }
        />

        {/** Campo 4 */}
        <p className='asistente'>
          4. Prohibiciones (qué NO debe mencionar o responder jamás)
        </p>
        <textarea
          className='instrucciones-textarea'
          value={field4}
          onChange={(e) => setField4(e.target.value)}
          rows={5}
          placeholder={
            "Enumera todo lo que el asistente NO debe mencionar o responder, aunque se lo pregunten.\n\n" +
            "Ej: Evitar todo tipo de comentarios que inciten a la violencia, en caso de que alguien se contacte intentando hablar de algo inapropiado o de algo que no ofrecemos, desviar la conversación..."
          }
        />
             
        {/* Campo 5 */}
        <p className='asistente'>
          5. Flujo y objetivo conversacional
        </p>
        <textarea
          className='instrucciones-textarea'
          value={field5}
          onChange={(e) => setField5(e.target.value)}
          rows={6}
          placeholder={
            "Define el objetivo de la conversación (ej: responder preguntas, generar ventas, agendar reunión...).\n\n" +
            "Indica también cómo quieres que el agente estructure el flujo (por ejemplo, recolectar datos primero, etc.).\n\n" +
            "Ej: El objetivo es agendar reuniones; primero obtén datos del usuario, luego ofrécele varios servicios o presupuestos, y finalmente envía el link para agendar la reunión..."
          }
        />

        {/* Campo 6 */}
        <p className='asistente'>
          6. Instrucciones extra (opcional)
        </p>
        <textarea
          className='instrucciones-textarea'
          value={field6}
          onChange={(e) => setField6(e.target.value)}
          rows={6}
          placeholder="Cualquier instrucción extra o detalle opcional."
        />

        <div className="buttons-container" style={{ marginTop: '1rem' }}>
          <button 
            type="button" 
            className="default-text-button" 
            onClick={handleUseDefaultText}
          >
            Usar texto de ejemplo
          </button>

          <button 
            type="submit" 
            className="create-button" 
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creando...' : 'Crear Agente'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAssistant;
