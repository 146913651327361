import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import { AssistantContext } from '../context/AssistantContext';
import arrowLeft from '../assets/arrow-left.png';
import arrowRight from '../assets/arrow-right.png';
import './Header.css';
import {
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '../firebase';
import bellIcon from '../assets/bell-icon.png';
import { useAuth } from '../context/AuthContext';
import freeConversationsIcon from '../assets/icon-free-conversations.png';
import paidConversationsIcon from '../assets/icon-paid-conversations.png';

const Header = ({ toggleWideSidebar, isWideSidebarVisible }) => {
  const { isLightMode } = useContext(ThemeContext);
  const { assistantName } = useContext(AssistantContext);
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [totalThreadsUsed, setTotalThreadsUsed] = useState(0);
  const [purchasedThreadsAvailable, setPurchasedThreadsAvailable] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [remainingFreeConversations, setRemainingFreeConversations] = useState(200);
  const [remainingPaidConversations, setRemainingPaidConversations] = useState(0);

  // Extraer el assistantId de la URL si existe
  // Podríamos tener paths como: /messages/asst_xxx o /chat/asst_xxx
  // Suponemos un patrón: /messages/asst_{id} o /chat/asst_{id}
  let assistantIdFromURL = null;
  const msgMatch = location.pathname.match(/^\/messages\/(asst_[^/]+)/);
  const chatMatch = location.pathname.match(/^\/chat\/(asst_[^/]+)/);

  if (msgMatch) {
    assistantIdFromURL = msgMatch[1]; // asst_fBFPIR6fGR1wQHVmNXydPPWv
  } else if (chatMatch) {
    assistantIdFromURL = chatMatch[1];
  }

  useEffect(() => {
    if (!currentUser) {
      console.warn('Usuario no autenticado');
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const totalThreadsUsed = userData.totalThreads || 0;
        const purchasedThreadsAvailable = userData.purchasedThreads || 0;

        setTotalThreadsUsed(totalThreadsUsed);
        setPurchasedThreadsAvailable(purchasedThreadsAvailable);

        const baseFreeConversations = 200;
        const remainingFree = Math.max(0, baseFreeConversations - totalThreadsUsed);
        const remainingPaid = Math.max(0, purchasedThreadsAvailable);

        setRemainingFreeConversations(remainingFree);
        setRemainingPaidConversations(remainingPaid);
      }
    }, (error) => {
      console.error('Error en el listener del documento del usuario:', error);
    });

    return () => {
      unsubscribeUser();
    };
  }, [currentUser]);

  // Determinar el título del encabezado basado en la ruta actual
  let headerTitle = 'Inicio';
  if (location.pathname.startsWith('/messages')) {
    headerTitle = 'Chats';
  }
  if (location.pathname.startsWith('/bubble')) {
    headerTitle = 'Pruebas';
  }
  if (location.pathname === '/profile') {
    headerTitle = 'Perfil';
  }
  if (location.pathname === '/support') {
    headerTitle = 'Soporte';
  }
  if (location.pathname === '/crm') {
    headerTitle = 'CRM';
  }
  
  if (location.pathname === '/analytics') {
    headerTitle = 'Analíticas';
  } else {
    const chatPathMatch = location.pathname.match(/^\/chat\/([^/]+)/);
    if (chatPathMatch) {
      headerTitle = 'Editor IA';
    }
  }

  

  // Determinar el nombre actual del asistente basado en la ruta
  let currentAssistantName = 'PAGINA';
  if (location.pathname.startsWith('/messages')) {
    currentAssistantName = 'MIS CHATS';
  }
  if (location.pathname.startsWith('/bubble')) {
    currentAssistantName = 'BURBUJA WEB';
  }
  if (location.pathname === '/profile') {
    currentAssistantName = 'PERFIL DEL USUARIO';
  }
  if (location.pathname === '/support') {
    currentAssistantName = 'TICKETS';
  }
  if (location.pathname === '/crm') {
    currentAssistantName = 'PANEL';
  }
  if (location.pathname === '/analytics') {
    currentAssistantName = 'ANALITICAS (PROXIMAMENTE)';
  } else {
    const chatPathMatch = location.pathname.match(/^\/chat\/([^/]+)/);
    if (chatPathMatch) {
      currentAssistantName = assistantName;
    } else if (location.pathname === '/') {
      currentAssistantName = 'MIS AGENTES'; 
    }
  }

  return (
    <div className={`header ${isLightMode ? 'light-mode' : 'dark-mode'}`}>
      <div className="header-left">
        <h1 className="header-title">{headerTitle}</h1>
        <button className="toggle-sidebar-button" onClick={toggleWideSidebar}>
          <img
            src={isWideSidebarVisible ? arrowRight : arrowLeft}
            alt={isWideSidebarVisible ? 'Arrow Right' : 'Arrow Left'}
            className="arrow-icon"
            style={{ width: '1.5em', height: 'auto' }} // Ajusta el tamaño aquí
          />
        </button>
        <span className="page-title">{currentAssistantName}</span>
      </div>

      <div className="header-right">
  {/* Contador de Conversaciones Gratuitas */}
  <div className="conversation-counter free-conversations">
    <img src={freeConversationsIcon} alt="Conversaciones Gratuitas" className="counter-icon" />
    <span className="counter-badge-free-conversations">{remainingFreeConversations}</span>
    <span className="tooltip-text">Conversaciones Gratuitas</span>
  </div>

  {/* Contador de Conversaciones Pagas */}
  <div className="conversation-counter paid-conversations">
    <img src={paidConversationsIcon} alt="Conversaciones Pagas" className="counter-icon" />
    <span className="counter-badge">{remainingPaidConversations}</span>
    <span className="tooltip-text">Conversaciones Pagas</span>
  </div>

  {/* Botón de Notificaciones con Modal Anidado */}
  <div className="notification-bell" onClick={() => setIsModalOpen(!isModalOpen)}>
    <img src={bellIcon} alt="Notificaciones" className="bell-icon" />
    
    {/* Modal de Notificaciones */}
    {isModalOpen && (
      <div className="notification-modal-backdrop" onClick={() => setIsModalOpen(false)}>
        <div className="notification-modal" onClick={(e) => e.stopPropagation()}>
          <h2 className="notification-title">Notificaciones</h2>
          <h3 className="notification-subtitle">Conversaciones Restantes:</h3>
          {(remainingFreeConversations + remainingPaidConversations) <= 0 ? (
            <p className="notification-text">¡Te has quedado sin conversaciones!</p>
          ) : (
            <p className="notification-text">Te quedan {remainingFreeConversations + remainingPaidConversations} conversaciones en total.</p>
          )}
        </div>
      </div>
    )}
  </div>
  </div>
  </div> 
  );
};

export default Header;
