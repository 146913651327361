// src/context/SubscriptionContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const SubscriptionContext = createContext();

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};

export const SubscriptionProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [isExpired, setIsExpired] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Si no hay usuario logueado, marcamos que no está expirada,
    // y que ya no estamos "cargando" nada.
    if (!currentUser) {
      setIsExpired(false);
      setLoading(false);
      return;
    }

    setLoading(true); // Forzamos loading = true mientras escuchamos Firestore

    const userRef = doc(db, 'users', currentUser.uid);
    const unsubscribe = onSnapshot(
      userRef,
      (docSnapshot) => {
        if (!docSnapshot.exists()) {
          // Si el documento ni existe, consideramos expirado
          setIsExpired(true);
          setLoading(false);
          return;
        }

        const data = docSnapshot.data();
        const accessExpiresAt = data.accessExpiresAt?.toDate();

        if (!accessExpiresAt) {
          // Si no hay campo accessExpiresAt, asumimos expirado
          setIsExpired(true);
        } else {
          // Comparamos la fecha/hora exacta con "ahora"
          const now = new Date();
          if (accessExpiresAt <= now) {
            // accessExpiresAt es anterior o igual a "ahora" => vencido
            setIsExpired(true);
          } else {
            // Todavía es mayor que "ahora" => no vencido
            setIsExpired(false);
          }
        }

        setLoading(false); 
      },
      (error) => {
        console.error('Error al obtener datos de suscripción:', error);
        // Si hay error en la lectura, por seguridad lo marcamos expirado
        setIsExpired(true);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  return (
    <SubscriptionContext.Provider value={{ isExpired, loading }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
