// src/components/ThreadsViewer.js

import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import './ThreadsViewer.css';
import whatsappLogo from '../assets/whatsapp-logo.png';
import paperClipButton from '../assets/paper-clip-button.png';
import ControlAgenteIA from '../assets/Control-Agente-IA.png';
import webChatIcon from '../assets/web-chat-icon.png';

import { useAuth } from '../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import plusIconChat from '../assets/+-icon-chat.png';
import emojiIconChat from '../assets/emoji-icon-chat.png';
import clipIconChat from '../assets/clip-icon-chat.png';

import EmojiPicker from 'emoji-picker-react';

import { getAllAssistants } from '../services/openaiService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar as faStarSolid,
  faCaretDown,
  faTrashAlt,
  faEllipsisV,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import { FaWhatsapp } from 'react-icons/fa';

import backgroundChat from '../assets/background-chat.png';

import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

import { SelectedTeamMemberContext } from '../context/SelectedTeamMemberContext';

import { db } from '../firebase';
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// Configurar worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ThreadsViewer = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);

  const { selectedTeamMember, setSelectedTeamMember } = useContext(SelectedTeamMemberContext); // Uso del contexto

  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [loadingAgents, setLoadingAgents] = useState(true);
  const [loadingThreads, setLoadingThreads] = useState(false);
  const [showLoading, setShowLoading] = useState(false); // Nuevo estado para mostrar loading condicionalmente
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Para indicar si no hay agentes
  const [noAgents, setNoAgents] = useState(false);

  // ========== Switch local de la conversación
  const [isAutoResponseEnabled, setIsAutoResponseEnabled] = useState(true);

  // ========== NUEVO: Estado global (se lee de Firestore)
  const [globalAutoResponseEnabled, setGlobalAutoResponseEnabled] = useState(true);

  const [scrollOnLoad, setScrollOnLoad] = useState(true);
  const [isUserNearBottom, setIsUserNearBottom] = useState(true);
  const [unreadThreadIds, setUnreadThreadIds] = useState([]);

  const [assistantPassKey, setAssistantPassKey] = useState('');
  const [isSending, setIsSending] = useState(false);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  // Manejo del picker de emojis
  const handleEmojiClick = (emojiData, event) => {
    setNewMessage((prev) => prev + emojiData.emoji);
  };

  const lastMessageRef = useRef(null);

  // Límites por tipo de archivo
  const MAX_FILE_SIZE = {
    image: 5,
    video: 16,
    audio: 16,
    document: 100
  };

  const getMessageType = (mimeType) => {
    if (mimeType.startsWith('image/')) return 'image';
    if (mimeType.startsWith('video/')) return 'video';
    if (mimeType.startsWith('audio/')) return 'audio';
    return 'document';
  };

  const messageHistoryRef = useRef(null);
  const prevThreadsRef = useRef([]);
  const orderFilterRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);

  const openPreviewModal = (index) => {
    setCurrentPreviewIndex(index);
    setIsModalOpen(true);
  };

  const closePreviewModal = () => {
    setIsModalOpen(false);
  };

  // Para eliminar archivos en la previsualización
  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);

    const updatedPreviews = [...previewURLs];
    updatedPreviews.splice(index, 1);
    setPreviewURLs(updatedPreviews);
  };

  const [showOnlyUnread, setShowOnlyUnread] = useState(false);

  const [favoriteThreadIds, setFavoriteThreadIds] = useState([]);
  const [orderBy, setOrderBy] = useState('lastMessage');

  const { assistantId, threadId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // Manejo de conversaciones restantes
  const [totalThreads, setTotalThreads] = useState(0);
  const [purchasedThreads, setPurchasedThreads] = useState(0);
  const [remainingConversations, setRemainingConversations] = useState(200);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [showConversationLimitModal, setShowConversationLimitModal] = useState(false);

  // Modal para asignar miembros
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  // CRM Columns
  const [crmColumns, setCrmColumns] = useState([]);
  const [isMoveMenuOpen, setIsMoveMenuOpen] = useState(null);

  // Timeout del loading
  const loadingTimeoutRef = useRef(null);

  // Búsqueda en mensajes
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);

  // Búsqueda global
  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false);
  const [globalSearchTerm, setGlobalSearchTerm] = useState('');
  const [globalSearchResults, setGlobalSearchResults] = useState([]);
  const [isGlobalSearchLoading, setIsGlobalSearchLoading] = useState(false);

  const messageRefs = useRef([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Para controlar la vista en mobile
  const [showMobileThreadList, setShowMobileThreadList] = useState(true);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 1000) {
      setShowMobileThreadList(true);
      setSelectedThread(null);
    }
  }, [windowWidth]);

  const handleOrderChange = (e) => {
    setOrderBy(e.target.value);
  };

  // Manejo de archivos seleccionados
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewURLs, setPreviewURLs] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);

    const newPreviews = files.map((file) => {
      if (file.type.startsWith('image/')) {
        return {
          type: 'image',
          url: URL.createObjectURL(file),
          name: file.name
        };
      }
      else if (file.type.startsWith('video/')) {
        return {
          type: 'video',
          url: URL.createObjectURL(file),
          name: file.name
        };
      }
      else {
        return {
          type: 'document',
          url: URL.createObjectURL(file),
          name: file.name
        };
      }
    });
    setPreviewURLs(newPreviews);
  };

  const getPastelColor = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }

    const hue = Math.abs(hash) % 360;
    const saturation = 40 + (Math.abs(hash) % 21);
    const lightness = 80 + (Math.abs(hash) % 6);

    // Convertir HSL a RGBA pastel
    const c = (1 - Math.abs((2 * lightness) / 100 - 1)) * (saturation / 100);
    const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
    const m = lightness / 100 - c / 2;
    let r, g, b;

    if (hue >= 0 && hue < 60) {
      r = c; g = x; b = 0;
    } else if (hue >= 60 && hue < 120) {
      r = x; g = c; b = 0;
    } else if (hue >= 120 && hue < 180) {
      r = 0; g = c; b = x;
    } else if (hue >= 180 && hue < 240) {
      r = 0; g = x; b = c;
    } else if (hue >= 240 && hue < 300) {
      r = x; g = 0; b = c;
    } else {
      r = c; g = 0; b = x;
    }

    const R = Math.round((r + m) * 255);
    const G = Math.round((g + m) * 255);
    const B = Math.round((b + m) * 255);

    return `rgba(${R}, ${G}, ${B}, 0.7)`;
  };

  const getInitials = (name) => {
    const namesArray = name.trim().split(' ');
    if (namesArray.length === 1) {
      return namesArray[0].charAt(0).toUpperCase();
    } else {
      return (
        namesArray[0].charAt(0).toUpperCase() +
        namesArray[namesArray.length - 1].charAt(0).toUpperCase()
      );
    }
  };

  // Variantes para conversationOwner
  const getConversationOwnerVariants = (nombre, apellido) => {
    const baseName = `${nombre} ${apellido}`.trim();
    return [
      baseName,
      `${baseName} `
    ];
  };

  // Cargar miembros de equipo
  useEffect(() => {
    if (!currentUser) return;
    const fetchTeamMembersAndMktUsers = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userRef);
        if (!userDocSnap.exists()) return;

        const userData = userDocSnap.data();
        const equipoMembers = userData.equipo || [];

        let mktMembers = [];
        if (userData.accessCode === 'MKT') {
          const mktQuery = query(
            collection(db, 'users'),
            where('accessCode', '==', 'MKT')
          );
          const mktSnap = await getDocs(mktQuery);
          const mktUserSnapshots = mktSnap.docs;

          mktMembers = mktUserSnapshots
            .filter((snap) => snap.exists())
            .map((snap) => {
              const data = snap.data();
              if (data.nombre && data.apellido) {
                return {
                  id: snap.id,
                  type: 'real',
                  nombre: data.nombre,
                  apellido: data.apellido,
                  cargo: data.cargo || '',
                };
              } else if (data.name) {
                const nameParts = data.name.trim().split(' ');
                const nombre = nameParts[0];
                const apellido = nameParts.slice(1).join(' ') || '';
                return {
                  id: snap.id,
                  type: 'real',
                  nombre,
                  apellido,
                  cargo: data.cargo || '',
                };
              } else {
                return {
                  id: snap.id,
                  type: 'real',
                  nombre: '',
                  apellido: '',
                  cargo: '',
                };
              }
            });
        }

        const combinedTeamMembers = [
          ...equipoMembers.map((member, index) => ({
            id: `fictitious-${index}`,
            type: 'fictitious',
            nombre: member.nombre,
            apellido: member.apellido,
            cargo: member.cargo,
          })),
          ...mktMembers,
        ];

        setTeamMembers(combinedTeamMembers);
      } catch (error) {
        console.error('Error al cargar miembros del equipo y MKT:', error);
      }
    };
    fetchTeamMembersAndMktUsers();
  }, [currentUser]);

  // Asignar propietario
  const handleSelectTeamMember = async (member) => {
    if (!selectedThread || !selectedAgent) {
      alert('No se ha seleccionado un thread o agente.');
      return;
    }

    if (!member.id) {
      console.error('El miembro seleccionado no tiene un id definido:', member);
      alert('Error interno: el miembro seleccionado no tiene un ID válido.');
      return;
    }

    const conversationOwnerId = member.id;
    const conversationOwnerName = `${member.nombre} ${member.apellido}`.trim();

    try {
      const threadRef = doc(db, `threads_${selectedAgent.id}`, selectedThread.threadId);
      await updateDoc(threadRef, {
        conversationownerId: conversationOwnerId,
        conversationowner: conversationOwnerName,
      });

      setSelectedThread({
        ...selectedThread,
        conversationownerId: conversationOwnerId,
        conversationowner: conversationOwnerName,
      });

      alert(`Conversación asignada a ${conversationOwnerName} exitosamente.`);
    } catch (error) {
      console.error('Error al asignar conversationowner:', error);
      alert('Hubo un error al asignar la conversación al miembro seleccionado.');
    } finally {
      setIsTeamModalOpen(false);
    }
  };

  // Referencias a los mensajes para scroll focalizado
  useEffect(() => {
    if (selectedThread) {
      messageRefs.current = selectedThread.messageHistory.map(
        (_, i) => messageRefs.current[i] || React.createRef()
      );
    }
  }, [selectedThread]);

  // Búsqueda en el contenido de los mensajes
  const handleMessageSearch = () => {
    if (!searchQuery.trim()) return;

    const lowerCaseQuery = searchQuery.toLowerCase();
    const results = [];

    selectedThread.messageHistory.forEach((message, index) => {
      if (message.content.toLowerCase().includes(lowerCaseQuery)) {
        results.push(index);
      }
    });

    if (results.length > 0) {
      setSearchResults(results);
      setCurrentSearchIndex(0);

      const firstIndex = results[0];
      if (messageRefs.current[firstIndex] && messageRefs.current[firstIndex].current) {
        messageRefs.current[firstIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else {
      alert('No se encontró el término de búsqueda.');
      setSearchResults([]);
      setCurrentSearchIndex(0);
    }
  };

  const goToNextSearchResult = () => {
    if (searchResults.length === 0) return;

    const nextIndex = (currentSearchIndex + 1) % searchResults.length;
    setCurrentSearchIndex(nextIndex);

    const messageIndex = searchResults[nextIndex];
    if (messageRefs.current[messageIndex] && messageRefs.current[messageIndex].current) {
      messageRefs.current[messageIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const goToPreviousSearchResult = () => {
    if (searchResults.length === 0) return;

    const prevIndex = (currentSearchIndex - 1 + searchResults.length) % searchResults.length;
    setCurrentSearchIndex(prevIndex);

    const messageIndex = searchResults[prevIndex];
    if (messageRefs.current[messageIndex] && messageRefs.current[messageIndex].current) {
      messageRefs.current[messageIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // Listener datos del usuario (para ver globalAutoResponse, threads restantes, etc.)
  useEffect(() => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const updatedTotalThreads = userData.totalThreads || 0;
          const updatedPurchasedThreads = userData.purchasedThreads || 0;
          setTotalThreads(updatedTotalThreads);
          setPurchasedThreads(updatedPurchasedThreads);

          const remaining = Math.max(0, 200 + updatedPurchasedThreads - updatedTotalThreads);
          setRemainingConversations(remaining);

          if (remaining <= 0) {
            setIsLimitReached(true);
            setIsAutoResponseEnabled(false);
            setShowConversationLimitModal(true);
          } else {
            setIsLimitReached(false);
          }

          // Leer el valor globalAutoResponseEnabled
          if (typeof userData.globalAutoResponseEnabled === 'boolean') {
            setGlobalAutoResponseEnabled(userData.globalAutoResponseEnabled);
          } else {
            setGlobalAutoResponseEnabled(true);
          }
        }
      },
      (error) => {
        console.error('Error en listener del usuario:', error);
      }
    );

    return () => {
      unsubscribeUser();
    };
  }, [currentUser]);

  // Mover un thread a una columna de CRM
  const handleMoveToColumn = async (thread, targetColumnId) => {
    if (!currentUser) return;

    try {
      const { messageHistory, ...threadWithoutMessages } = thread;
      const threadToMove = {
        ...threadWithoutMessages,
        createdAt: thread.createdAt || new Date().toISOString(),
      };

      const columnRef = doc(db, 'crm', targetColumnId);
      const columnSnap = await getDoc(columnRef);

      if (!columnSnap.exists()) {
        throw new Error('Columna no encontrada');
      }

      const columnData = columnSnap.data();
      const updatedThreads = [...(columnData.threads || [])];

      const threadIndex = updatedThreads.findIndex((t) => t.threadId === threadToMove.threadId);
      if (threadIndex === -1) {
        updatedThreads.push(threadToMove);
      } else {
        updatedThreads[threadIndex] = threadToMove;
      }

      await updateDoc(columnRef, {
        threads: updatedThreads,
        lastUpdatedBy: currentUser.uid
      });

      alert(`Chat movido exitosamente a ${columnData.name}`);
    } catch (error) {
      console.error('Error al mover el thread:', error);
      alert('Error al mover el chat');
    } finally {
      setIsMoveMenuOpen(null);
    }
  };

  // Al dar click en un thread de la lista
  const handleThreadClick = (thread) => {
    navigate(`/messages/${thread.assistantId}/${thread.threadId}`);
    setSelectedThread(thread);
    if (windowWidth <= 1000) {
      setShowMobileThreadList(false);
    }
  };

  // Listener para columnas del CRM
  useEffect(() => {
    if (!currentUser) return;

    const crmQuery = query(
      collection(db, 'crm'),
      where('authorizedUsers', 'array-contains', currentUser.uid)
    );

    const unsubscribe = onSnapshot(
      crmQuery,
      (snapshot) => {
        const columns = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setCrmColumns(columns);
      },
      (error) => {
        console.error('Error en listener de CRM:', error);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  // 1) OBTENER ASISTENTES Y REDIRECCIONAR
  useEffect(() => {
    const fetchAgentsAndHandleNavigation = async () => {
      if (!currentUser) {
        setLoadingAgents(false);
        return;
      }
      try {
        const assistantsData = await getAllAssistants(currentUser.uid);
        setAgents(assistantsData);

        if (assistantsData.length === 0) {
          // No hay agentes
          setNoAgents(true);
          setLoadingAgents(false);
          return;
        } else {
          setNoAgents(false);
        }

        if (!assistantId) {
          // No hay assistantId en la URL => navegar al primero
          navigate(`/messages/${assistantsData[0].id}`);
        } else {
          // Hay assistantId => setear selectedAgent si lo encuentra
          const agent = assistantsData.find((a) => a.id === assistantId);
          if (agent) {
            setSelectedAgent(agent);
          } else {
            console.error('assistantId inválido o no pertenece al usuario actual');
            setSelectedAgent(null);
            setSelectedThread(null);
            navigate('/threads');
          }
        }
      } catch (error) {
        console.error('Error al obtener agentes:', error);
      }
      setLoadingAgents(false);
    };

    fetchAgentsAndHandleNavigation();
  }, [assistantId, currentUser, navigate]);

  // 2) Al tener selectedTeamMember o selectedAgent, cargar threads
  const fetchThreadsForAllAssistantsForMember = async (owner) => {
    if (!currentUser) return;
    setLoadingThreads(true);
    loadingTimeoutRef.current = setTimeout(() => {
      setShowLoading(true);
    }, 300);

    try {
      const assistantsData = await getAllAssistants(currentUser.uid);
      let allThreads = [];

      const ownerVariants = getConversationOwnerVariants(owner.nombre, owner.apellido);

      for (const assistant of assistantsData) {
        const threadsCollection = collection(db, `threads_${assistant.id}`);
        const q = query(threadsCollection, where('conversationowner', 'in', ownerVariants));
        const querySnapshot = await getDocs(q);

        const threadsData = querySnapshot.docs.map((doc) => ({
          threadId: doc.id,
          ...doc.data(),
          assistantId: assistant.id,
        }));

        allThreads = allThreads.concat(threadsData);
      }

      const sortedThreads = allThreads.slice().sort((a, b) => {
        const aLastMessageDate = new Date(a.messageHistory[a.messageHistory.length - 1]?.createdAt);
        const bLastMessageDate = new Date(b.messageHistory[b.messageHistory.length - 1]?.createdAt);
        return bLastMessageDate - aLastMessageDate;
      });

      setThreads(sortedThreads);
    } catch (error) {
      console.error('Error al obtener threads:', error);
    }

    setLoadingThreads(false);
    clearTimeout(loadingTimeoutRef.current);
    setShowLoading(false);
  };

  const fetchThreadsByAssistantId = async (assistantId, owner = null) => {
    setLoadingThreads(true);
    loadingTimeoutRef.current = setTimeout(() => {
      setShowLoading(true);
    }, 300);

    try {
      let threadsCollection = collection(db, `threads_${assistantId}`);
      let q;

      if (owner) {
        q = query(threadsCollection, where('conversationowner', '==', `${owner.nombre} ${owner.apellido}`));
      } else {
        q = query(threadsCollection);
      }

      const querySnapshot = await getDocs(q);
      const threadsData = querySnapshot.docs.map((doc) => ({
        threadId: doc.id,
        ...doc.data(),
        assistantId: assistantId,
      }));

      const sortedThreads = threadsData.slice().sort((a, b) => {
        const aLastMessageDate = new Date(a.messageHistory[a.messageHistory.length - 1]?.createdAt);
        const bLastMessageDate = new Date(b.messageHistory[b.messageHistory.length - 1]?.createdAt);
        return bLastMessageDate - aLastMessageDate;
      });

      setThreads(sortedThreads);
    } catch (error) {
      console.error('Error al obtener los threads del asistente:', error);
    }

    setLoadingThreads(false);
    clearTimeout(loadingTimeoutRef.current);
    setShowLoading(false);
  };

  // Cuando cambia selectedAgent o selectedTeamMember, cargar threads
  useEffect(() => {
    if (selectedTeamMember) {
      fetchThreadsForAllAssistantsForMember(selectedTeamMember);
      if (!threadId) {
        setSelectedThread(null);
      }
    } else if (selectedAgent) {
      fetchThreadsByAssistantId(selectedAgent.id, selectedTeamMember);
      if (!threadId) {
        setSelectedThread(null);
      }
    } else {
      setThreads([]);
      setSelectedThread(null);
    }
  }, [selectedAgent, selectedTeamMember, threadId]);

  // Seleccionar el thread según threadId
  useEffect(() => {
    if (threadId && threads.length > 0) {
      const thread = threads.find((t) => t.threadId === threadId);
      if (thread) {
        handleSelectThread(thread);
      } else {
        console.error('threadId inválido o no pertenece a los threads filtrados');
        if (selectedTeamMember) {
          navigate(`/messages`);
        } else {
          navigate(`/threads/${assistantId}`);
        }
      }
    }
  }, [threadId, threads, assistantId, selectedTeamMember, navigate]);

  // Marcar/desmarcar favorito
  const toggleFavorite = async (threadId) => {
    if (!selectedAgent && !selectedTeamMember) return;
    const thread = threads.find((t) => t.threadId === threadId);
    if (!thread) return;

    const threadRef = doc(db, `threads_${thread.assistantId}`, threadId);
    try {
      const threadSnapshot = await getDoc(threadRef);
      if (threadSnapshot.exists()) {
        const currentIsFavorite = threadSnapshot.data().isFavorite || false;
        await updateDoc(threadRef, {
          isFavorite: !currentIsFavorite,
        });
      }
    } catch (error) {
      console.error('Error al actualizar favorito:', error);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const countryCode = phoneNumber.slice(0, 2);
    const areaCode = phoneNumber.slice(2, 3);
    const cityCode = phoneNumber.slice(3, 5);
    const firstPart = phoneNumber.slice(5, 9);
    const secondPart = phoneNumber.slice(9);
    return `+${countryCode} ${areaCode} ${cityCode} ${firstPart} ${secondPart}`;
  };

  // Manejo del scroll para detectar si el usuario está al fondo
  useEffect(() => {
    const messageContainer = messageHistoryRef.current;
    if (!messageContainer) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = messageContainer;
      const threshold = 10;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - threshold;
      setIsUserNearBottom(isAtBottom);
    };

    messageContainer.addEventListener('scroll', handleScroll);
    return () => {
      messageContainer.removeEventListener('scroll', handleScroll);
    };
  }, [messageHistoryRef]);

  // Filtrar agentes en el input de búsqueda
  useEffect(() => {
    setFilteredAgents(agents);
  }, [agents]);

  // Filtrar threads según searchTerm, showOnlyUnread y orderBy
  const filteredThreads = useMemo(() => {
    let sorted = [...threads];
    if (searchTerm.trim() !== '') {
      sorted = sorted.filter((thread) =>
        thread.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (showOnlyUnread) {
      sorted = sorted.filter((thread) =>
        unreadThreadIds.includes(thread.threadId)
      );
    }

    if (orderBy === 'name') {
      sorted.sort((a, b) =>
        (a.senderName || '').localeCompare(b.senderName || '')
      );
    } else if (orderBy === 'date') {
      sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (orderBy === 'lastMessage') {
      sorted.sort((a, b) => {
        const aLast = new Date(a.messageHistory[a.messageHistory.length - 1]?.createdAt);
        const bLast = new Date(b.messageHistory[b.messageHistory.length - 1]?.createdAt);
        return bLast - aLast;
      });
    } else if (orderBy === 'read') {
      sorted = sorted.filter(
        (thread) => !unreadThreadIds.includes(thread.threadId)
      );
    } else if (orderBy === 'unread') {
      sorted = sorted.filter((thread) =>
        unreadThreadIds.includes(thread.threadId)
      );
    } else if (orderBy === 'favorites') {
      sorted = sorted.filter((thread) => thread.isFavorite);
    }

    threads.forEach((thread) => {
      if (thread.threadId !== selectedThread?.threadId) {
        const oldThread = prevThreadsRef.current.find((t) => t.threadId === thread.threadId);
        if (oldThread && thread.messageHistory.length > oldThread.messageHistory.length) {
          if (!unreadThreadIds.includes(thread.threadId)) {
            unreadThreadIds.push(thread.threadId);
          }
        }
      }
    });
    prevThreadsRef.current = threads;

    return sorted;
  }, [threads, searchTerm, orderBy, showOnlyUnread, unreadThreadIds, selectedThread]);

  // Listener en Tiempo Real para Threads
  useEffect(() => {
    if (!currentUser) return;

    let unsubscribeList = [];

    const subscribeToAllAssistantsThreads = async (owner) => {
      try {
        const assistantsData = await getAllAssistants(currentUser.uid);
        let combinedThreads = [];
        const ownerVariants = getConversationOwnerVariants(owner.nombre, owner.apellido);

        assistantsData.forEach((assistant) => {
          const threadsCollection = collection(db, `threads_${assistant.id}`);
          const q = query(threadsCollection, where('conversationowner', 'in', ownerVariants));

          const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
              const assistantThreads = querySnapshot.docs.map((doc) => ({
                threadId: doc.id,
                ...doc.data(),
                assistantId: assistant.id,
              }));

              setThreads((prevThreads) => {
                const otherThreads = prevThreads.filter((t) => t.assistantId !== assistant.id);
                return [...otherThreads, ...assistantThreads];
              });
            },
            (error) => {
              console.error(`Error en el listener de threads_${assistant.id}:`, error);
            }
          );

          unsubscribeList.push(unsubscribe);
        });
      } catch (error) {
        console.error('Error al suscribirse a los threads de los asistentes:', error);
      }
    };

    const subscribeToSelectedAgentThreads = (agent) => {
      const threadsCollection = collection(db, `threads_${agent.id}`);
      const q = query(threadsCollection);

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const agentThreads = querySnapshot.docs.map((doc) => ({
            threadId: doc.id,
            ...doc.data(),
            assistantId: agent.id,
          }));
          setThreads(agentThreads);
        },
        (error) => {
          console.error(`Error en el listener de threads_${agent.id}:`, error);
        }
      );

      unsubscribeList.push(unsubscribe);
    };

    if (selectedTeamMember) {
      subscribeToAllAssistantsThreads(selectedTeamMember);
    } else if (selectedAgent) {
      subscribeToSelectedAgentThreads(selectedAgent);
    } else {
      setThreads([]);
    }

    return () => {
      unsubscribeList.forEach((unsub) => unsub());
    };
  }, [selectedAgent, selectedTeamMember, currentUser]);

  // Darle formato al contenido (reemplazar links, markdown, etc.)
  const formatMessageContent = (content, highlight = null) => {
    let formattedContent = content;
    if (highlight) {
      const regex = new RegExp(`(${highlight})`, 'gi');
      formattedContent = formattedContent.replace(regex, '<span class="highlight">$1</span>');
    }

    // Markdown, listas, etc.
    formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    formattedContent = formattedContent.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    formattedContent = formattedContent.replace(/^###\s*(.*)$/gm, '<h3>$1</h3>');
    formattedContent = formattedContent.replace(/^-\s(.*)$/gm, '<li>$1</li>');
    formattedContent = formattedContent.replace(/^\*\s(.*)$/gm, '<li>$1</li>');
    formattedContent = formattedContent.replace(/^\d+\.\s(.*)$/gm, '<li>$1</li>');
    formattedContent = formattedContent.replace(/<\/li>\n<li>/g, '</li><li>');
    formattedContent = formattedContent.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>');

    const urlRegex = /(https?:\/\/[^\s)]+)([.,;:)\?!]+)?/g;
    formattedContent = formattedContent.replace(urlRegex, (match, p1, p2) => {
      return `<a href="${p1}" target="_blank" rel="noopener noreferrer">${p1}</a>${p2 || ''}`;
    });

    formattedContent = formattedContent.replace(/\n/g, '<br>');
    return formattedContent;
  };

  // Manejo del input de búsqueda de agentes
  const handleAgentSearch = (term) => {
    setAgentSearchTerm(term);
    if (term.trim() === '') {
      setFilteredAgents(agents);
    } else {
      setFilteredAgents(
        agents.filter((agent) =>
          agent.name.toLowerCase().includes(term.toLowerCase())
        )
      );
    }
  };

  // Manejo del input de búsqueda de threads
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // Búsqueda global en todos los chats
  const handleGlobalSearch = async () => {
    if (!globalSearchTerm.trim()) {
      setGlobalSearchResults([]);
      return;
    }
    setIsGlobalSearchLoading(true);
    try {
      const searchTermLower = globalSearchTerm.toLowerCase();
      const matchingThreads = threads.filter((thread) =>
        thread.messageHistory.some((msg) =>
          msg.content.toLowerCase().includes(searchTermLower)
        )
      );
      setGlobalSearchResults(matchingThreads);
    } catch (error) {
      console.error('Error durante la búsqueda global:', error);
    } finally {
      setIsGlobalSearchLoading(false);
    }
  };

  // Scroll automático al último mensaje
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedThread?.messageHistory]);

  // Al seleccionar un thread
  const handleSelectThread = (thread) => {
    setSelectedThread(thread);
    setIsAutoResponseEnabled(thread.autoResponseEnabled);
    setScrollOnLoad(true);
    setIsUserNearBottom(true);
    setUnreadThreadIds((prevIds) => prevIds.filter((id) => id !== thread.threadId));
  };

  // Para saber si han pasado más de 24h desde el último mensaje
  function isOlderThan24Hours(dateString) {
    if (!dateString) return false;
    const now = new Date();
    const msgDate = new Date(dateString);
    const diff = now - msgDate;
    return diff > 24 * 60 * 60 * 1000; 
  }

  const lastMsg = selectedThread?.messageHistory?.[selectedThread.messageHistory.length - 1];
  const olderThan24h = lastMsg ? isOlderThan24Hours(lastMsg.createdAt) : false;

  // Enviar mensaje
  const handleSendMessage = async () => {
    if (remainingConversations <= 0) {
      setIsLimitReached(true);
      setShowConversationLimitModal(true);
      return;
    }
    const messageToSend = newMessage;
    setNewMessage('');
    setIsSending(true);
    try {
      if (messageToSend.trim() === '' && selectedFiles.length === 0) {
        setIsSending(false);
        return;
      }
      if (selectedThread.phoneNumber) {
        // Enviar a WhatsApp
        try {
          if (selectedFiles.length > 0) {
            for (const file of selectedFiles) {
              const messageType = getMessageType(file.type);
              const fileSizeMB = file.size / (1024 * 1024);
              if (fileSizeMB > MAX_FILE_SIZE[messageType]) {
                agregarMensajeAlHistorial(
                  'system',
                  `El archivo "${file.name}" excede el límite de ${MAX_FILE_SIZE[messageType]} MB.`
                );
                setIsSending(false);
                return;
              }
            }
            const formData = new FormData();
            formData.append('assistantId', selectedThread.assistantId);
            formData.append('phoneNumber', selectedThread.phoneNumber);
            if (messageToSend.trim() !== '') {
              formData.append('caption', messageToSend);
            }
            selectedFiles.forEach((file) => {
              formData.append('files', file);
            });
            const response = await fetch('https://backend.thethingapp.com/send-media', {
              method: 'POST',
              body: formData,
            });
            const data = await response.json();
            if (!data.success) {
              console.error('Error al enviar media:', data.error);
              if (data.error && data.error.message.includes('demasiado grande')) {
                agregarMensajeAlHistorial('system', 'El archivo es demasiado grande.');
              } else {
                agregarMensajeAlHistorial('system', 'Hubo un error al enviar el archivo.');
              }
            } else {
              console.log('Media enviado con éxito!');
            }
            setSelectedFiles([]);
            setPreviewURLs([]);
            setNewMessage('');
          } else {
            // mensaje de texto
            const response = await fetch('https://backend.thethingapp.com/send-message', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                phoneNumber: selectedThread.phoneNumber,
                message: messageToSend,
                assistantId: selectedThread.assistantId,
              }),
            });
            const data = await response.json();
            if (!data.success) {
              console.error('Error al enviar mensaje a WhatsApp:', data.error);
              agregarMensajeAlHistorial('system', 'Hubo un error al enviar el mensaje.');
            }
            setNewMessage('');
          }
        } catch (error) {
          console.error('Error al enviar archivo a WhatsApp:', error);
          agregarMensajeAlHistorial('system', 'Hubo un error al enviar el archivo.');
        }
      } else {
        // Guardar mensaje manual en Chat Web
        try {
          const response = await fetch('https://backend.thethingapp.com/api/save-web-chat-thread', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              passKey: assistantPassKey,
              assistantId: selectedThread.assistantId,
              threadId: selectedThread.threadId,
              messages: [
                {
                  role: 'assistant',
                  content: messageToSend
                }
              ]
            }),
          });
          const data = await response.json();
          if (!data.success) {
            console.error('Error al guardar mensaje manual:', data.error);
            agregarMensajeAlHistorial('system', 'Hubo un error al guardar el mensaje.');
          }
        } catch (error) {
          console.error('Error al conectar con el backend:', error);
          agregarMensajeAlHistorial('system', 'Hubo un error al conectar con el servidor.');
        }
      }
    } finally {
      setIsSending(false);
    }
  };

  // Enviar plantilla (ejemplo)
  const handleSendTemplate = async () => {
    if (!selectedThread || !selectedThread.threadId || !selectedThread.phoneNumber) {
      alert('Falta información para enviar la plantilla.');
      return;
    }
    try {
      const response = await fetch('https://backend.thethingapp.com/send-template', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          phoneNumber: selectedThread.phoneNumber,
          assistantId: selectedThread.assistantId,
          threadId: selectedThread.threadId,
        }),
      });
      const data = await response.json();
      if (!data.success) {
        alert('Error al enviar la plantilla: ' + data.error);
      } else {
        alert('Plantilla enviada con éxito.');
      }
    } catch (error) {
      console.error('Error al enviar la plantilla:', error);
      alert('Hubo un error al enviar la plantilla.');
    }
  };

  // Agregar un mensaje "interno" al historial
  const agregarMensajeAlHistorial = (role, contenido) => {
    if (!selectedThread) return;
    const nuevoMensaje = {
      role: role,
      content: contenido,
      createdAt: new Date().toISOString(),
      messageType: 'text',
    };
    setSelectedThread((prevThread) => ({
      ...prevThread,
      messageHistory: [...prevThread.messageHistory, nuevoMensaje],
    }));
  };

  // AQUI la magia: si globalAutoResponseEnabled = false, forzamos el switch local a OFF
  const finalAutoResponseEnabled = globalAutoResponseEnabled && isAutoResponseEnabled;

  const toggleAutoResponse = async () => {
    if (!globalAutoResponseEnabled) {
      alert('La respuesta automática GLOBAL está desactivada. No puedes encender la local.');
      return;
    }
    if (remainingConversations <= 0) {
      alert('No te quedan conversaciones. No puedes activar la respuesta automática.');
      return;
    }

    const newAutoResponseEnabled = !isAutoResponseEnabled;
    setIsAutoResponseEnabled(newAutoResponseEnabled);

    if (selectedThread) {
      try {
        const response = await fetch('https://backend.thethingapp.com/update-thread-status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            threadId: selectedThread.threadId,
            autoResponseEnabled: newAutoResponseEnabled,
            assistantId: selectedThread.assistantId,
          }),
        });
        const data = await response.json();
        if (!data.success) {
          console.error('Error al actualizar el estado de respuesta automática:', data.error);
        }
      } catch (error) {
        console.error('Error al conectar con el backend:', error);
      }
    }
  };

  const formatDate = (dateString) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
    };
    return new Date(dateString)
      .toLocaleString('es-ES', options)
      .replace(',', '')
      .replace(':', ':');
  };

  // Borrar un thread
  const handleDeleteThread = async (threadId) => {
    const thread = threads.find((t) => t.threadId === threadId);
    if (!thread) return;

    const confirmDelete = window.confirm(
      '¿Estás seguro de que deseas eliminar este chat? Esta acción no se puede deshacer.'
    );
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, `threads_${thread.assistantId}`, threadId));
      alert('Chat eliminado con éxito.');
      if (selectedThread && selectedThread.threadId === threadId) {
        setSelectedThread(null);
      }
    } catch (error) {
      console.error('Error al eliminar el chat:', error);
      alert('Hubo un error al eliminar el chat.');
    }
  };

  // Quitar propietario de un thread
  const removeOwner = async (threadId, assistantId) => {
    try {
      const threadRef = doc(db, `threads_${assistantId}`, threadId);
      await updateDoc(threadRef, {
        conversationowner: null
      });
      alert('Propietario removido exitosamente');
    } catch (error) {
      console.error('Error al quitar propietario:', error);
      alert('Error al quitar el propietario');
    }
  };

  // Renderizar el menú al mover un thread a otra columna
  const renderMoveDropdown = (thread) => {
    if (!isMoveMenuOpen || isMoveMenuOpen !== thread.threadId) return null;
    return (
      <div className="order-dropdown-messages">
        <div className="move-dropdown-content">
          {crmColumns.map((column) => (
            <div
              key={column.id}
              className="move-option"
              onClick={() => handleMoveToColumn(thread, column.id)}
            >
              {column.name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // --- RETURN PRINCIPAL ---
  return (
    <div className="threads-viewer">
      {/* Si todavía cargamos agentes o si estamos cargando threads */}
      {(loadingAgents || (loadingThreads && showLoading)) ? (
        <div
          className="loading"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'calc(100vh - 100px)',
            fontSize: 'xx-large',
          }}
        >
          Cargando...
        </div>
      ) : noAgents ? (
        // Si no hay agentes, mostramos este mensaje
        <div className="no-agent-message" style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 'large',
          textAlign: 'center',
          padding: '1em',
        }}>
          Para ver los mensajes debe crear un agente y conectarlo 
          a su web o a su número de WhatsApp.
        </div>
      ) : (
        // Si sí hay agentes (y no estamos en loading), mostramos la UI normal:
        <>
          {/* Lista de threads (sidebar) */}
          <div
            className="threads-list"
            style={{
              display: windowWidth <= 1000
                ? (showMobileThreadList ? 'flex' : 'none')
                : 'flex',
            }}
          >
            <div className="threads-list-header">
              {!isGlobalSearchOpen && (
                <>
                  <div className="header-left">
                    <div className="pending-switch-container">
                      <div
                        className="global-search-icon-wrapper"
                        onClick={() => setIsGlobalSearchOpen(!isGlobalSearchOpen)}
                        title="Buscar en todos los chats"
                      >
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                      </div>
                      <span>Pendientes</span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={showOnlyUnread}
                          onChange={() => setShowOnlyUnread(!showOnlyUnread)}
                        />
                        <span className="slider pending-slider"></span>
                      </label>
                    </div>
                  </div>

                  <div className="header-right">
                    <div
                      className="order-filter"
                      ref={orderFilterRef}
                      onClick={() => setIsOrderDropdownOpen(!isOrderDropdownOpen)}
                    >
                      <span>Ordenar por</span>
                      <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
                      {isOrderDropdownOpen && (
                        <div className="order-dropdown-messages">
                          <div
                            onClick={() => {
                              setOrderBy('name');
                              setIsOrderDropdownOpen(false);
                            }}
                          >
                            Nombre
                          </div>
                          <div
                            onClick={() => {
                              setOrderBy('date');
                              setIsOrderDropdownOpen(false);
                            }}
                          >
                            Fecha de creación
                          </div>
                          <div
                            onClick={() => {
                              setOrderBy('lastMessage');
                              setIsOrderDropdownOpen(false);
                            }}
                          >
                            Fecha de último mensaje
                          </div>
                          <div
                            onClick={() => {
                              setOrderBy('favorites');
                              setIsOrderDropdownOpen(false);
                            }}
                          >
                            Destacado
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="global-search-section">
                {isGlobalSearchOpen && (
                  <div className="global-search-container">
                    <input
                      type="text"
                      className="global-search-input"
                      placeholder="Buscar en todos los chats..."
                      value={globalSearchTerm}
                      onChange={(e) => setGlobalSearchTerm(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleGlobalSearch();
                        }
                      }}
                      aria-label="Buscar en todos los chats"
                    />
                    <button onClick={handleGlobalSearch} className="global-search-button">
                      Buscar
                    </button>
                    <button
                      className="global-search-close-button"
                      onClick={() => setIsGlobalSearchOpen(false)}
                      aria-label="Cerrar búsqueda global"
                    >
                      ×
                    </button>
                  </div>
                )}
              </div>
            </div>

            {(selectedTeamMember || selectedAgent) ? (
              <ul className="threads-list-items">
                {globalSearchTerm.trim() ? (
                  isGlobalSearchLoading ? (
                    <li className="loading-global-search">Buscando...</li>
                  ) : globalSearchResults.length > 0 ? (
                    // Si hay resultados de la búsqueda global
                    globalSearchResults.map((thread) => (
                      <li
                        key={thread.threadId}
                        className="thread-item"
                        onClick={() => handleThreadClick(thread)}
                      >
                        {/* Estrella de favorito */}
                        <div
                          className="favorite-star"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFavorite(thread.threadId);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={thread.isFavorite ? faStarSolid : faStarRegular}
                            className="star-icon"
                            style={{
                              color: thread.isFavorite ? '#FFD700' : '#CCCCCC',
                              cursor: 'pointer',
                              fontSize: '1.2em',
                            }}
                            aria-label={
                              thread.isFavorite
                                ? 'Quitar favorito'
                                : 'Marcar como favorito'
                            }
                          />
                        </div>
                        <div
                          className="thread-info"
                          style={{ display: 'flex', alignItems: 'start' }}
                        >
                          {thread.phoneNumber ? (
                            <div
                              className="avatar-container"
                              style={{
                                backgroundColor: getPastelColor(
                                  thread.senderName || 'Chat Web'
                                ),
                              }}
                            >
                              <span className="avatar-initials">
                                {getInitials(thread.senderName || 'Chat Web')}
                              </span>
                              <FaWhatsapp className="whatsapp-icon" />
                            </div>
                          ) : (
                            <div className="web-chat-avatar-container">
                              <img
                                src={webChatIcon}
                                alt="Web Chat Icon"
                                className="web-chat-icon"
                              />
                            </div>
                          )}
                          <div
                            className="thread-details-preview"
                            style={{ flex: 1, marginLeft: '10px' }}
                          >
                            <p
                              className="thread-sender-name"
                              style={{
                                margin: '0',
                                fontFamily: 'Outfit',
                              }}
                            >
                              {thread.senderName || 'Chat Web'}
                            </p>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <p
                                className="thread-last-message thread-preview-text"
                                style={{
                                  margin: 0,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '18em',
                                  color: '#AC9ED4',
                                }}
                              >
                                {thread.messageHistory[thread.messageHistory.length - 1]?.content || ''}
                              </p>
                              {unreadThreadIds.includes(thread.threadId) && (
                                <span
                                  className="unread-dot"
                                  style={{
                                    marginLeft: '8px',
                                    backgroundColor: 'red',
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    flexShrink: 0,
                                  }}
                                ></span>
                              )}
                            </div>
                          </div>
                          <span
                            className="thread-date-info"
                            style={{
                              minWidth: '6em',
                              textAlign: 'right',
                              marginLeft: '10px',
                            }}
                          >
                            {formatDate(thread.messageHistory[thread.messageHistory.length - 1]?.createdAt)}
                          </span>
                          <div
                            className="options-thread"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsMoveMenuOpen(
                                thread.threadId === isMoveMenuOpen ? null : thread.threadId
                              );
                            }}
                            title="Opciones"
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisV}
                              className="options-icon"
                              aria-label="Opciones"
                            />
                            {renderMoveDropdown(thread)}
                          </div>
                        </div>
                        <div
                          className="delete-thread"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteThread(thread.threadId);
                          }}
                          title="Eliminar chat"
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="trash-icon"
                            aria-label="Eliminar chat"
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="no-search-results">
                      No se encontraron conversaciones con el término "{globalSearchTerm}".
                    </li>
                  )
                ) : (
                  // Lista normal de threads (sin búsqueda global)
                  filteredThreads.map((thread) => (
                    <li
                      key={thread.threadId}
                      className="thread-item"
                      onClick={() => handleThreadClick(thread)}
                    >
                      <div
                        className="favorite-star"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(thread.threadId);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={thread.isFavorite ? faStarSolid : faStarRegular}
                          className="star-icon"
                          style={{
                            color: thread.isFavorite ? '#FFD700' : '#CCCCCC',
                            cursor: 'pointer',
                            fontSize: '1.2em',
                          }}
                          aria-label={
                            thread.isFavorite ? 'Quitar favorito' : 'Marcar como favorito'
                          }
                        />
                      </div>
                      <div
                        className="thread-info"
                        style={{ display: 'flex', alignItems: 'start' }}
                      >
                        {thread.phoneNumber ? (
                          <div
                            className="avatar-container"
                            style={{
                              backgroundColor: getPastelColor(
                                thread.senderName || 'Chat Web'
                              ),
                            }}
                          >
                            <span className="avatar-initials">
                              {getInitials(thread.senderName || 'Chat Web')}
                            </span>
                            <FaWhatsapp className="whatsapp-icon" />
                          </div>
                        ) : (
                          <div className="web-chat-avatar-container">
                            <img
                              src={webChatIcon}
                              alt="Web Chat Icon"
                              className="web-chat-icon"
                            />
                          </div>
                        )}
                        <div
                          className="thread-details-preview"
                          style={{ flex: 1, marginLeft: '10px' }}
                        >
                          <p
                            className="thread-sender-name"
                            style={{
                              margin: '0',
                              fontFamily: 'Outfit',
                            }}
                          >
                            {thread.senderName || 'Chat Web'}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              className="thread-last-message thread-preview-text"
                              style={{
                                margin: 0,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '18em',
                                color: '#AC9ED4',
                              }}
                            >
                              {thread.messageHistory[thread.messageHistory.length - 1]?.content || ''}
                            </p>
                            {unreadThreadIds.includes(thread.threadId) && (
                              <span
                                className="unread-dot"
                                style={{
                                  marginLeft: '8px',
                                  backgroundColor: 'red',
                                  borderRadius: '50%',
                                  width: '10px',
                                  height: '10px',
                                  flexShrink: 0,
                                }}
                              ></span>
                            )}
                          </div>
                        </div>
                        <span
                          className="thread-date-info"
                          style={{
                            minWidth: '6em',
                            textAlign: 'right',
                            marginLeft: '10px',
                          }}
                        >
                          {formatDate(thread.messageHistory[thread.messageHistory.length - 1]?.createdAt)}
                        </span>
                        <div
                          className="options-thread"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsMoveMenuOpen(
                              thread.threadId === isMoveMenuOpen ? null : thread.threadId
                            );
                          }}
                          title="Opciones"
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisV}
                            className="options-icon"
                            aria-label="Opciones"
                          />
                          {renderMoveDropdown(thread)}
                        </div>
                      </div>
                      <div
                        className="delete-thread"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteThread(thread.threadId);
                        }}
                        title="Eliminar chat"
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="trash-icon"
                          aria-label="Eliminar chat"
                        />
                      </div>
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <div
                className="no-agent-message"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 'large',
                  textAlign: 'center',
                  padding: '1em',
                }}
              >
                                  <div className="spinner-container">
                    <div className="spinner"></div>
                  </div>
              </div>
            )}
          </div>

          {/* Zona de detalle del thread seleccionado */}
          {(selectedTeamMember || selectedAgent) ? (
            selectedThread ? (
              <div
                className="thread-details"
                style={{
                  backgroundImage: `url(${backgroundChat})`,
                  backgroundColor: '#010321',
                  backgroundSize: 'auto',
                  backgroundRepeat: 'repeat',
                  backgroundAttachment: 'fixed',
                  display:
                    windowWidth <= 1000
                      ? showMobileThreadList
                        ? 'none'
                        : 'flex'
                      : 'flex',
                }}
              >
                <div className="thread-details-content">
                  <div className="thread-info-header">
                    <div className="thread-info-sender">
                      {windowWidth <= 1000 && (
                        <button
                          className="mobile-back-button"
                          onClick={() => {
                            setShowMobileThreadList(true);
                          }}
                        >
                          <i className="fa fa-arrow-left mobile-arrow-icon-back" aria-hidden="true"></i>
                        </button>
                      )}
                      {(!isSearchOpen || windowWidth > 1000) && (
                        <>
                          {selectedThread.phoneNumber ? (
                            <div
                              className="avatar-header-container"
                              style={{
                                backgroundColor: getPastelColor(
                                  selectedThread.senderName || 'Chat Web'
                                ),
                              }}
                            >
                              <span className="avatar-header-initials">
                                {getInitials(selectedThread.senderName || 'Chat Web')}
                              </span>
                              <FaWhatsapp className="whatsapp-icon-header" />
                            </div>
                          ) : (
                            <div className="web-chat-avatar-header-container">
                              <img
                                src={webChatIcon}
                                alt="Web Chat Icon"
                                className="web-chat-icon-header"
                              />
                            </div>
                          )}
                          <div className="sender-details">
                            <span className="thread-sender-name">
                              {selectedThread.senderName || 'Chat Web'}
                            </span>
                            {!isSearchOpen && (
                              <span className="thread-phone-number">
                                {selectedThread.phoneNumber
                                  ? formatPhoneNumber(selectedThread.phoneNumber)
                                  : 'Website'}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="add-contact">
                      {selectedThread.conversationowner && (
                        <div className="conversation-owner">
                          <strong>Tomó el chat:</strong>
                          <span className="owner-name-threads">
                            {selectedThread.conversationowner}
                          </span>
                        </div>
                      )}
                      {!isSearchOpen && (
                        <div className="auto-response-container">
                          <div
                            className="tooltip-wrapper"
                            data-tooltip={
                              finalAutoResponseEnabled
                                ? 'Autorespuesta IA: ON'
                                : 'Autorespuesta IA: OFF'
                            }
                          >
                            <img
                              src={ControlAgenteIA}
                              alt="Control Agente IA"
                              className="control-agente-ia-icon"
                            />
                            {/* Switch que se muestra OFF si globalAutoResponseEnabled = false */}
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={finalAutoResponseEnabled}
                                onChange={toggleAutoResponse}
                              />
                              <span
                                className={`slider-threads ${
                                  finalAutoResponseEnabled ? 'on' : 'off'
                                }`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      )}
                      {!isSearchOpen && (
                        <div
                          className="search-icon-wrapper"
                          onClick={() => setIsSearchOpen(!isSearchOpen)}
                          title="Buscar mensajes"
                        >
                          <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        </div>
                      )}
                      {isSearchOpen && (
                        <div className="message-search-container">
                          <input
                            type="text"
                            className="message-search-input"
                            placeholder="Buscar en mensajes..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleMessageSearch();
                              }
                            }}
                          />
                          <button
                            className="search-now-button"
                            onClick={handleMessageSearch}
                            title="Buscar"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                          {searchResults.length > 0 && (
                            <div className="search-navigation">
                              <button
                                onClick={goToPreviousSearchResult}
                                className="search-nav-button"
                                title="Anterior"
                              >
                                &larr;
                              </button>
                              <span className="search-counter">
                                {currentSearchIndex + 1} / {searchResults.length}
                              </span>
                              <button
                                onClick={goToNextSearchResult}
                                className="search-nav-button"
                                title="Siguiente"
                              >
                                &rarr;
                              </button>
                            </div>
                          )}
                          <button
                            className="global-search-close-button"
                            onClick={() => setIsSearchOpen(false)}
                            aria-label="Cerrar búsqueda de mensajes"
                          >
                            ×
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <ul className="message-history" ref={messageHistoryRef}>
                    {selectedThread.messageHistory.map((msg, index) => {
                      const isLast = index === selectedThread.messageHistory.length - 1;
                      return (
                        <li
                          key={index}
                          className={`message-item ${msg.role}`}
                          style={{
                            display: 'flex',
                            justifyContent: msg.role === 'user' ? 'flex-start' : 'flex-end',
                            marginBottom: '1em',
                          }}
                          ref={isLast ? lastMessageRef : messageRefs.current[index]}
                        >
                          <div
                            className={`message-content ${msg.role}`}
                            style={{
                              borderRadius: '20px',
                              padding: '1.3em 2em',
                              maxWidth: msg.messageType === 'audio' ? '80%' : '45%',
                              alignSelf: msg.role === 'user' ? 'flex-start' : 'flex-end',
                              width: 'fit-content',
                            }}
                          >
                            {(() => {
                              switch (msg.messageType) {
                                case 'image':
                                  return (
                                    <div>
                                      <img
                                        src={msg.content}
                                        alt="Imagen recibida"
                                        style={{ maxWidth: '100%', borderRadius: '10px' }}
                                      />
                                      {msg.caption && (
                                        <p style={{ marginTop: '5px', fontStyle: 'italic' }}>
                                          {msg.caption}
                                        </p>
                                      )}
                                    </div>
                                  );
                                case 'audio':
                                  return (
                                    <audio controls style={{ width: '100%', minWidth: '300px' }}>
                                      <source src={msg.content} />
                                      Tu navegador no soporta el elemento de audio.
                                    </audio>
                                  );
                                case 'video':
                                  return (
                                    <video
                                      controls
                                      style={{ width: '100%', minWidth: '300px' }}
                                    >
                                      <source src={msg.content} />
                                      Tu navegador no soporta el elemento de video.
                                    </video>
                                  );
                                case 'document':
                                  if (msg.content.endsWith('.pdf')) {
                                    return (
                                      <div style={{ width: '100%', height: '500px', overflow: 'auto' }}>
                                        <Document
                                          file={msg.content}
                                          onLoadError={(error) => console.error('Error al cargar el PDF:', error)}
                                        >
                                          <Page pageNumber={1} width={600} />
                                        </Document>
                                      </div>
                                    );
                                  } else {
                                    const gViewerUrl = `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
                                      msg.content
                                    )}`;
                                    return (
                                      <iframe
                                        src={gViewerUrl}
                                        style={{ width: '100%', height: '500px', border: 'none' }}
                                        title="Vista previa GDocs"
                                      />
                                    );
                                  }
                                default:
                                  // Mensaje de texto normal
                                  return (
                                    <span
                                      className="message-text"
                                      dangerouslySetInnerHTML={{
                                        __html: formatMessageContent(
                                          msg.content,
                                          searchResults.includes(index) && index === searchResults[currentSearchIndex]
                                            ? searchQuery
                                            : null
                                        ),
                                      }}
                                    />
                                  );
                              }
                            })()}
                            <span
                              className="message-timestamp"
                              style={{ marginTop: '5px', display: 'block' }}
                            >
                              {formatDate(msg.createdAt)}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>

                  <div className="new-message-container">
                    {isTeamModalOpen && (
                      <div className="team-modal-backdrop" onClick={() => setIsTeamModalOpen(false)}>
                        <div className="team-modal" onClick={(e) => e.stopPropagation()}>
                          <h2 className="team-modal-title">Selecciona un Miembro del Equipo</h2>
                          <ul className="team-modal-list">
                            {teamMembers.map((member, index) => (
                              <li key={index} className="team-modal-item">
                                <div
                                  className="team-member-info"
                                  onClick={() => handleSelectTeamMember(member)}
                                >
                                  <span className="team-member-name">
                                    {`${member.nombre} ${member.apellido}`}
                                  </span>
                                  {member.cargo && (
                                    <span className="team-member-role">
                                      {member.cargo}
                                    </span>
                                  )}
                                </div>
                                {selectedThread?.conversationowner ===
                                  `${member.nombre} ${member.apellido}` && (
                                  <button
                                    className="remove-owner-button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeOwner(
                                        selectedThread.threadId,
                                        selectedThread.assistantId
                                      );
                                    }}
                                    title="Quitar propietario"
                                  >
                                    ×
                                  </button>
                                )}
                              </li>
                            ))}
                          </ul>
                          <button
                            className="team-modal-close-button"
                            onClick={() => setIsTeamModalOpen(false)}
                          >
                            Cerrar
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="message-icons">
                      <img
                        src={plusIconChat}
                        alt="Agregar"
                        className="message-icon-one"
                        onClick={() => setIsTeamModalOpen(true)}
                      />
                      <img
                        src={emojiIconChat}
                        alt="Emoji"
                        className="message-icon"
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      />
                      <img
                        src={clipIconChat}
                        alt="Adjuntar"
                        className="message-icon"
                        onClick={handleFileIconClick}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        multiple
                        onChange={handleFileChange}
                      />
                    </div>
                    {showEmojiPicker && (
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '60px',
                          left: '20px',
                          zIndex: 999,
                        }}
                      >
                        <EmojiPicker onEmojiClick={handleEmojiClick} />
                      </div>
                    )}

                    {previewURLs.length > 0 && (
                      <div className="file-preview-container">
                        {previewURLs.map((preview, idx) => (
                          <div
                            key={idx}
                            className="file-preview-item"
                            style={{
                              transform: `translate(${idx * 5}px, -${idx * 5}px)`,
                              zIndex: previewURLs.length - idx,
                            }}
                          >
                            {idx === 0 && (
                              <button
                                className="remove-file-button"
                                onClick={() => handleRemoveFile(idx)}
                                aria-label={`Eliminar ${preview.name}`}
                              >
                                ×
                              </button>
                            )}
                            {preview.type === 'image' && (
                              <img
                                src={preview.url}
                                alt={`Preview ${preview.name}`}
                                className="preview-image"
                                onClick={() => openPreviewModal(idx)}
                              />
                            )}
                            {preview.type === 'video' && (
                              <video
                                src={preview.url}
                                className="preview-video"
                                muted
                                onClick={() => openPreviewModal(idx)}
                              />
                            )}
                            {preview.type === 'document' && (
                              <div className="preview-document">
                                <p>{preview.name}</p>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}

                    {olderThan24h ? (
                      <div style={{ margin: '0em' }}>
                        <p
                          style={{
                            color: 'white',
                            fontSize: '1.3em',
                            fontWeight: '500',
                            fontFamily: 'Outfit',
                          }}
                        >
                          Han pasado más de 24hs desde el último mensaje.
                        </p>
                      </div>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="new-message-input"
                          placeholder="Escribe tu mensaje..."
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') handleSendMessage();
                          }}
                        />
                        <button
                          className="send-message-button"
                          onClick={handleSendMessage}
                          disabled={remainingConversations <= 0 || isSending}
                        >
                          {isSending ? (
                            <div
                              className="spinner"
                              aria-label="Enviando mensaje"
                            ></div>
                          ) : (
                            <img
                              src={paperClipButton}
                              alt="Enviar"
                              className="send-button-image"
                            />
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="no-thread-selected" style={{ flex: 1 }} />
            )
          ) : (
            <div className="no-agent-selected" style={{ flex: 1 }} />
          )}
        </>
      )}

      {/* Modal de límite de conversaciones */}
      {showConversationLimitModal && (
        <div className="conversation-limit-modal-backdrop">
          <div className="conversation-limit-modal">
            <button
              className="conversation-limit-close-button"
              onClick={() => setShowConversationLimitModal(false)}
            >
              ×
            </button>
            <h2 className="conversation-limit-title">Sin conversaciones disponibles</h2>
            <p className="conversation-limit-text">
              Has agotado tus conversaciones gratuitas. <br />
              Debes comprar más en{' '}
              <a
                href="https://thethingapp.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                thethingapp.com
              </a>. <br />
              No podrás enviar ni recibir nuevos mensajes.
            </p>
            <button
              className="conversation-limit-button"
              onClick={() =>
                window.open('https://thethingapp.com', '_blank', 'noopener,noreferrer')
              }
            >
              Comprar más conversaciones
            </button>
          </div>
        </div>
      )}

      {/* Modal de previsualización de imágenes / videos / docs */}
      {isModalOpen && (
        <div className="modal-backdrop" onClick={closePreviewModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="modal-close-button"
              onClick={closePreviewModal}
              aria-label="Cerrar modal"
            >
              ×
            </button>
            <div className="modal-images-container">
              {previewURLs.map((preview, idx) => (
                <div
                  key={idx}
                  className={`modal-image-item ${
                    idx === currentPreviewIndex ? 'active' : ''
                  }`}
                >
                  {preview.type === 'image' && (
                    <img
                      src={preview.url}
                      alt={`Detalle ${preview.name}`}
                      className="modal-image"
                    />
                  )}
                  {preview.type === 'video' && (
                    <video src={preview.url} controls className="modal-video" />
                  )}
                  {preview.type === 'document' && (
                    <div className="modal-document">
                      <p>{preview.name}</p>
                    </div>
                  )}
                  <button
                    className="remove-file-button-modal"
                    onClick={() => handleRemoveFile(idx)}
                    aria-label={`Eliminar ${preview.name}`}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreadsViewer;
