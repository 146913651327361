// src/App.js

import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import AssistantsPage from './components/AssistantsPage';
import AssistantChat from './components/AssistantChat';
import './App.css';
import { ChatBubbleFrontend } from './components/ChatBubbleFrontend';
import Register from './components/Register';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import SolicitarAcceso from './components/SolicitarAcceso';
import { ApiKeyProvider } from './context/ApiKeyContext';
import TemplatesPage from './components/Templates'; // Página ya existente
import ThreadsViewer from './components/ThreadsViewer';
import Header from './components/Header'; 
import Sidebar from './components/Sidebar';
import WideSidebar from './components/WideSidebar';
import { ThemeProvider, ThemeContext } from './context/ThemeContext';
import MisAsistentes from './components/MisAsistentes';
import backgroundApp from './assets/background-app.webp'; 
import PrivacyPolicy from './PrivacyPolicy';
import ServiceConditions from './ServiceConditions';
import { AssistantProvider } from './context/AssistantContext'; 
import { SelectedAgentProvider } from './context/SelectedAgentContext';
import CompleteSignUp from './components/CompleteSignUp';
import ComingSoon from './components/ComingSoon';
import Profile from './components/Profile';
import Analytics from './components/Analytics';
import Support from './components/Support';
import { SubscriptionProvider } from './context/SubscriptionContext';
import SubscriptionExpiredModal from './components/SubscriptionExpiredModal';
import ResetPassword from './components/ResetPassword';
import HandleAction from './components/HandleAction';
import CRM from './components/CRM';
import { SearchProvider } from './context/SearchContext';
import { SelectedTeamMemberProvider } from './context/SelectedTeamMemberContext';
import MobileHeader from './components/HeaderMobile';

function AppContent() {
  const { isLightMode } = useContext(ThemeContext);
  const location = useLocation();

  
  // Identificar páginas de autenticación:
  const isAuthPage = ['/login', '/reset-password', '/handle-action'].includes(location.pathname);
  const isAgentPage = location.pathname.startsWith('/chat')  ;
  const isProfilePage = location.pathname.startsWith('/profile') ;

  // Identificar si es página de mensajes/chat para ajustar padding:
  const isThreadsPage = location.pathname.startsWith('/messages') 
    || location.pathname.startsWith('/chat/') 
    || location.pathname.startsWith('/crm') 
    || location.pathname === '/support';


      // Estados para detección de ventanas
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    // Limpieza al desmontar
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Estado sidebar ancho:
  
  const [isWideSidebarVisible, setWideSidebarVisible] = useState(true);
  const toggleWideSidebar = () => {
    if (!isAuthPage) {
      setWideSidebarVisible(!isWideSidebarVisible);
    }
  };

  let appClassName = 'App';
  let appStyle = {};

  // Aplicar estilo login-register si es página auth:
  if (isAuthPage) {
    appClassName += ' login-register';
    appStyle = {
      backgroundImage: `url(${backgroundApp})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
    };
  }   if (isAgentPage) {
    appStyle = {
      backgroundImage: `none`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor:'#020321', 
      minHeight: '100vh',
    };
  } else {
    appStyle = {
      backgroundImage: `url(${backgroundApp})`,
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
    };
  }

  const contentContainerClass = `
  ${isThreadsPage ? 'content-container no-padding' : 'content-container'} 
  ${isWideSidebarVisible && windowWidth >= 1000 ? 'wide-sidebar-visible' : ''} 
  ${isAgentPage ? 'is-agent-page' : ''}
  ${isProfilePage ? 'is-profile-page' : ''}
`.trim();




  return (
    <div className={`${appClassName} the-thing-global-text-style`} style={appStyle}>

<SearchProvider>
<SelectedTeamMemberProvider>
      <AssistantProvider>
        <SelectedAgentProvider>
          <AuthProvider>
            <ApiKeyProvider>
              <SubscriptionExpiredModal />

{/* Si NO es AuthPage y la pantalla es <=1000, muestro el MobileHeader */}
{!isAuthPage && windowWidth <= 1000 && (
  <MobileHeader />
)}

{/* Si NO es AuthPage y la pantalla es >1000, muestro el Header de escritorio */}
{!isAuthPage && windowWidth > 1000 && (
  <Header 
    toggleWideSidebar={toggleWideSidebar}
    isWideSidebarVisible={isWideSidebarVisible}
  />
)}

{/* Renderizamos Header, Sidebar y WideSidebar solo si NO es auth y windowWidth < 1280 */}
{!isAuthPage && windowWidth > 1000 && (
                    <>
                      <Sidebar />
                      <WideSidebar isVisible={isWideSidebarVisible} />
                    </>
                  )}

              {isAuthPage ? (
                // Rutas de autenticación (sin content-container)
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/handle-action" element={<HandleAction />} />
                </Routes>
              ) : (
                // Rutas no auth (envueltas en content-container)
                <div className={contentContainerClass}>
                  <Routes>
                    <Route path="/" element={<ProtectedRoute element={<MisAsistentes isWideSidebarVisible={isWideSidebarVisible} />} />} />
                    <Route path="/chat/:assistantId" element={<ProtectedRoute element={<AssistantChat />} />} />
                    <Route path="/bubble/" element={<ProtectedRoute element={<ChatBubbleFrontend />} />} />
                    <Route path="/bubble/:assistantId" element={<ProtectedRoute element={<ChatBubbleFrontend />} />} />
                    <Route path="/solicitar-acceso" element={<SolicitarAcceso />} />
                    <Route path="/templates" element={<ProtectedRoute element={<TemplatesPage />} />} />
                    <Route path="/messages" element={<ProtectedRoute element={<ThreadsViewer />} />} />
                    <Route path="/messages/:assistantId" element={<ProtectedRoute element={<ThreadsViewer />} />} />

                    {/* Nueva Ruta para manejar threadId */}
                    <Route path="/messages/:assistantId/:threadId" element={<ProtectedRoute element={<ThreadsViewer />} />} />

                    <Route path="/crm" element={
                      <ProtectedRoute element={
                        <CRM isWideSidebarVisible={isWideSidebarVisible} />
                      } />
                    } />
                    <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/service-conditions" element={<ServiceConditions />} />
                    <Route path="/completesignup" element={<CompleteSignUp />} />
                    <Route path="/coming-soon" element={<ComingSoon />} />
                    <Route path="/analytics" element={<ProtectedRoute element={<Analytics />} />} />
                    <Route path="/support" element={<ProtectedRoute element={<Support />} />} />

                  </Routes>
                </div>
              )}
            </ApiKeyProvider>
          </AuthProvider>
        </SelectedAgentProvider>
      </AssistantProvider>
      </SelectedTeamMemberProvider>
      </SearchProvider>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <Router>
        <AuthProvider>
          <SubscriptionProvider>
            <AppContent />
          </SubscriptionProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
